import React, { useContext, useState } from 'react'
import { Card, CardContent, Checkbox, Grid, InputBase, Pagination, Stack, styled, } from '@mui/material'
import DataContext from '../../../../context/DataContext';
import SearchIcon from "@mui/icons-material/Search";
import apiService from '../../../../api/apiService';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: "24px",
    backgroundColor: "rgb(255 255 255)",
    border: "1px solid #00000017",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#d9d9d9",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            width: "19ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));

const DisplayLogo = () => {
    const { setPageLoading, hasPrivilege } = useContext(DataContext);

    const [page, setPage] = useState(1);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [totalPage, setTotalPage] = useState(1);
    const [listData, setListData] = useState([])

    useDidMountEffect(() => {
        listApi();
    }, [searchInputValue, page]);

    const listApi = () => {
        setPageLoading(true);
        setListData([]);
        var data = {
            listSize: 30,
            pageNumber: page,
            searchString: searchInputValue,
        };
        apiService("department/nodal/list", data, "post")
            .then((res) => {
                setPageLoading(false);
                if (res && res.data) {
                    setTotalPage(res.data.totalPages);
                    if (res.data.departments) {
                        setListData(res.data.departments);
                    }
                }
            })
            .catch((err) => {
                console.error("Error fetching Blogs:", err);
            });
    };
    const handleToggleChange = (departmentId, currentStatus) => {
        const newStatus = !currentStatus;
        const data = {
            id: departmentId,
            display: newStatus,
        };
        debugger
        apiService("department/display/save", data, "post")
            .then((res) => {
                if (res && res.data.responseStatus === "Success") {
                    listApi();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const handleSearch = (e) => {
        setSearchInputValue(e.target.value);
        setPage(1)
    };
    const handleChange = (event, value) => {
        setPage(value);
    };
    return (
        <div className="event-datas-list py-2">
            <div className="sec-clrk">
                <div className="sec-filtersk">
                    <div>
                        <p style={{fontSize:'12px'}}><span style={{fontWeight:'600'}}>Note :</span> Please <span><img src="/images/Banner/icons8-tick.gif" alt="verified_gif" className='verified_gif'/></span>to display logo</p>
                    </div>
                </div>
                <div className="search-div ml-auto">
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder={"Search by Name"}
                            inputProps={{ "aria-label": "search" }}
                            onChange={handleSearch}
                            type="search"
                        />
                    </Search>
                </div>
            </div>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div>
                        {listData.length > 0 ? (
                            <>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                                    {listData &&
                                        listData.map((item, i) => (
                                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                                <div className="card_sec1">
                                                    <Card key={i} className={item.display === true ? "card-comp-adm-display-selected" : "card-comp-adm-display"}>
                                                        <div className='text-right'>
                                                            {hasPrivilege("73") && (
                                                                <Checkbox
                                                                    checked={item.display}
                                                                    onChange={() =>
                                                                        handleToggleChange(item.id, item.display)
                                                                    } color="success"
                                                                    size="small"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="imag-border-display">
                                                            <img
                                                                className="comp-logo-disp"
                                                                src={item.logoName ? item.logoName : '/images/oneTn/onetn_fav.png'}
                                                                alt="company-logo"
                                                            />
                                                        </div>
                                                        <CardContent >
                                                            <div className="autho-name-height-disp">
                                                                <p className="admcrd-para-color-displ">{item.name}</p>
                                                                <p className="autho-name text-center">{item.authorityName}</p>
                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            </Grid>
                                        ))}
                                </Grid>
                                {
                                    listData.length > 0 && totalPage > 1 && (
                                        <div className="event-pagination text-center mt-3">
                                            <Stack spacing={2}>
                                                <Pagination
                                                    count={totalPage}
                                                    page={page}
                                                    onChange={handleChange}
                                                />
                                            </Stack>
                                        </div>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                <div className="text-center nodatacss_admin">
                                    <h5 className="mb-4">No Data Found</h5>
                                    <div>
                                        <img
                                            src="/images/blue horizontal.png"
                                            alt="blue horizontal.png"
                                            className="logo_dash img-fluid"
                                        />
                                    </div>
                                    <div className="mt-5">
                                        <h3 className="title_p opa">OneTN</h3>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default DisplayLogo
