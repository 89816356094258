import React, { useContext, useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { School } from "@mui/icons-material";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";

const ForStartupsmenu = () => {
  const { setPageLoading } = useContext(DataContext)
  const [selectedMenu, setSelectedMenu] = useState();
  const [serviceId, setServiceId] = useState(0);
  const [serviceLists, setServiceLists] = useState([]);
  const [selectedContent, setSelectedContent] = useState([])


  useDidMountEffect(() => {
    servicedropdownApi();
  }, []);
  useDidMountEffect(() => {
    menuListApi();
  }, [serviceId]);


  const servicedropdownApi = () => {

    apiService(`home/service/list`, "", "unauthget")
      .then((result) => {
        if (result && result.data && result.data.services) {
          setServiceLists(result.data.services);
          if (result.data.services.length > 0) {
            setSelectedMenu(result.data.services[0].service);
            setServiceId(result.data.services[0].id);
            setBigImg(result.data.services[0].imageName3);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [bigImg, setBigImg] = useState("")
  const handleServiceSelect = (val, id, image) => {
    setSelectedMenu(val);
    setServiceId(id);
    setBigImg(image)
  };
  const menuListApi = () => {
    setPageLoading(true);
    const req = { serviceId ,listSize:1000,pageNumber:1}
    apiService(`home/startups/menu`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data && result.data.projects) {
          setSelectedContent(result.data.projects);
        } else {
          setSelectedContent([]);
        }
      })
      .catch((err) => {
        setPageLoading(false);
        console.error("Error fetching projects:", err);
      });
  };
  const handleRedirecturl = (url) => {
    debugger
    if (url !== undefined) {
      const fullUrl = /^https?:\/\//.test(url) ? url : `https://${url}`;
      window.open(fullUrl, "_blank");
    }
  }
  return (
    <Grid container sx={{ padding: "30px 65px 0px 65px;" }} spacing={3}>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        sx={{ display: "flex", justifyContent: "center" ,height:'460px', overflowY:'auto',overflowX:'hidden'}}
      >
        <Box>
          <List>
            {serviceLists.map((menu, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleServiceSelect(menu.service, menu.id, menu.imageName3)}
                className={`list_newmenu ${selectedMenu === menu.service ? "active" : ""
                  }`}
              >
                <ListItemIcon
                  className={`menu-icon ${selectedMenu === menu.service ? "active-icon" : ""
                    }`}
                >
                  {selectedMenu === menu.service ? (
                    <img src={menu.imageName2} alt="icons" />)
                    :
                    (<img src={menu.imageName1} alt="icons" />)}
                </ListItemIcon>
                <p style={{ fontWeight: "600", marginBottom: "0px" }}>
                  {menu.service}
                </p>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8} sx={{position: 'relative',height:'460px', overflowY:'auto',overflowX:'hidden' }}>
        <div><h3 className="list_title-ver" style={{ paddingTop:'20px'}}>{selectedMenu}<span><img src={'/images/menu-list/nextRep.png'} alt="arrow-icon" style={{ width: '100%', maxWidth: '22px', marginLeft: '5px' }} /></span></h3></div>
       {selectedContent.length>0 ?(
         <Box
         sx={{
           display: "flex",
           flexDirection: { xs: "column", md: "row" },
           alignItems: "stretch",

         }}
       >
         <Box sx={{ flex: 1, paddingRight: { xs: 0, md: "10px" } }}>
           {selectedContent
             .filter((_, index) => index % 2 === 0)
             .map((item, index, array) => (
               <Box
                 key={index}
                 sx={{
                   // marginBottom: "10px",
                   borderBottom:
                     index !== array.length - 1 ? "1px solid #ccc" : "none",
                   padding: '20px 0px'
                 }}
               >
                 <p style={{ fontWeight: "600", textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleRedirecturl(item.redirecturl)}>{item.name}</p>
                 <p className="list_title-ver-cate mb-0">{item.departmentName}</p>
               </Box>
             ))}
         </Box>
         <div
           style={{
             width: "1px",
             backgroundColor: "#ccc",
             margin: "0 10px",
           }}
         />
         <Box sx={{ flex: 1, paddingLeft: { xs: 0, md: "10px" } }}>
           {selectedContent
             .filter((_, index) => index % 2 !== 0)
             .map((item, index, array) => (
               <Box
                 key={index}
                 sx={{
                   // marginBottom: "10px",
                   borderBottom:
                     index !== array.length - 1 ? "1px solid #ccc" : "none",
                   padding: '20px 0px'
                 }}
               >
                 <p style={{ fontWeight: "600", textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleRedirecturl(item.redirecturl)}>{item.name}</p>
                 <p className="list_title-ver-cate mb-0" >{item.departmentName}</p>
               </Box>
             ))}
         </Box>
       </Box>
       ):(
        <h5 className="list_title-ver">No Datas Found</h5>
       )
      }
      </Grid>
      <div className="imag_big_menu">
        <img src={bigImg} alt="Menu_icon" className="logos-verticalmenu" />
      </div>
    </Grid>
  );
};

export default ForStartupsmenu;

