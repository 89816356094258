import { Box, Button, FormHelperText, Grid, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import notifyService from '../../../../api/notifySerivce';
import DataContext from '../../../../context/DataContext';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import Viewimage from '../../../../api/Viewimage';
import apiService from '../../../../api/apiService';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import { post } from 'jquery';

const Awardsandrecognitionsadd = ({ handleCloseModal, actions, awardlistId1, awardlistId2, listApi }) => {
    useDidMountEffect(() => {
        if (actions === "update") {
            getApi()
        }
    }, [])
    const { setPageLoading, VisuallyHiddenInput, isWhitespace } = useContext(DataContext);
    const [formData, setFormData] = useState({});
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const [viewImageName, setViewImageName] = useState("");
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({ values: formData });

    const getApi = () => {
        setPageLoading(true)
        apiService(`award/get?id1=${awardlistId1}&id2=${awardlistId2}`, '', 'get')
            .then((res) => {
                setPageLoading(false)
                if (res && res.data) {
                    setFormData(res.data)
                    if (res.data.logoName) {
                        res.data.logoUrl = res.data.logoName
                    }
                }
            }).catch((err) => {
                console.log(err);
            });
    }

    let logoselecetdFile = "";
    const handleFileUpload = (event, filekey, fileName, fileUrl, format) => {
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event;
            } else {
                logoselecetdFile = event.target.files[0];
            }

            const maxFileSize = 5 * 1024 * 1024;
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type;
                    var imagedatatype = imagetype.split("/");
                    var img_crt_type = imagedatatype[1];
                    if (
                        ((format === "file") && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf")) ||
                        ((format === "image") && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png"))
                    ) {
                        var currentImageName = getValues(fileName);
                        if (currentImageName && currentImageName !== logoselecetdFile.name) {
                            notifyService(
                                "success",
                                "Image Updated",
                                "The image has been successfully changed."
                            );
                        }
                        setValue(filekey, "");
                        setValue(fileName, "");
                        setValue(fileUrl, "");
                        var fileValue = logoselecetdFile;
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(",");
                            var ImageValue = spl[1];
                            var img_name = fileValue.name;
                            setValue(filekey, ImageValue);
                            setValue(fileName, img_name);
                            setValue(fileUrl, logourl1);
                            clearErrors(filekey);
                            clearErrors(fileName);
                            clearErrors(fileUrl);
                        };
                    } else {
                        notifyService(
                            "danger",
                            "File Format Invalid",
                            "Please check your file format"
                        );
                    }
                } else {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        `Maximum file size allowed is 5 MB`
                    );
                }
            }
        }
    };
    const handleClickOpen = (value, name) => {
        setViewImage(value);
        setViewImageName(name);
        setOpen(true);
    };
    const handleimgClose = () => {
        setOpen(false);
    };
    const onSubmit = (data) => {
        console.log(data);
        var req = {
            ...data,
        };

        if (actions === "update") {
            req.id1 = awardlistId1;
            req.id2 = awardlistId2;
        }

        debugger
        setPageLoading(true)
        apiService('award/save', req, 'post')
            .then((res) => {
                console.log(res);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        handleCloseModal();
                        notifyService("success", "Success", "Saved Sucessfully");
                        listApi()
                        reset()
                    }
                }
                handleCloseModal()
            }).catch((err) => {
                console.log(err);

            });

    }
    const onError = (event) => {
        console.log("error Data:", event);
        notifyService(
            "danger",
            "Submit Failed",
            "Please check the mandatory fields"
        );
    };

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={3} sx={{ marginTop: '5px' }}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div className="input_background mb-2">
                            <p>English</p>
                            <div className="mb-3">
                                <Controller
                                    name="title"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Title is required", validate: {
                                            noWhitespace: value => !isWhitespace(value) || 'Please enter valid Title',
                                            onlyEnglish: value => /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>_\-+=\[\]\/\\;:'`~\s]*$/.test(value) || 'Only English letters are allowed'

                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Title*"
                                            placeholder="Please Enter Title"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            size='small'
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.title && errors.title.message}
                                </FormHelperText>
                            </div>
                            <div className="mb-3">
                                <Controller
                                    name="description"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Description is required", validate: {
                                            noWhitespace: value => !isWhitespace(value) || 'Please enter valid Description',
                                            onlyEnglish: value => /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>_\-+=\[\]\/\\;:'`~\s]*$/.test(value) || 'Only English letters are allowed'

                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label=" Description *"
                                            placeholder="Please Enter Description"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            multiline
                                            rows={4}
                                            size='small'
                                            inputProps={{ maxLength: 500 }}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.description && errors.description.message}
                                </FormHelperText>
                                <FormHelperText sx={{ textAlign: 'end' }}>
                                    Max 500 characters.
                                </FormHelperText>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div className="input_background mb-2">
                            <p>தமிழ்</p>
                            <div className='mb-3'>
                                <Controller
                                    name="tamilTitle"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "தலைப்பு தேவை",
                                        validate: (value) => {
                                            const tamilRegex = /^[\u0B80-\u0BFF\d\s!@#$%^&*()_+=\[\]{};':"\\|,.<>/?]*$/;

                                            if (isWhitespace(value)) {
                                                return "சரியான தலைப்பை உள்ளிடவும்";
                                            }

                                            if (!tamilRegex.test(value)) {
                                                return "தமிழ் எழுத்துக்களுக்கு மட்டுமே அனுமதி";
                                            }

                                            return true;
                                        }
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="தலைப்பு*"
                                            placeholder="தயவுசெய்து தலைப்பை உள்ளிடவும்"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            size='small'
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.tamilTitle && errors.tamilTitle.message}
                                </FormHelperText>

                            </div>
                            <div className='mb-3'>
                                <Controller
                                    name="tamilDescription"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "விளக்கம் தேவை",
                                        validate: (value) => {
                                            const tamilRegex = /^[\u0B80-\u0BFF\d\s!@#$%^&*()_+=\[\]{};':"\\|,.<>/?]*$/;

                                            if (isWhitespace(value)) {
                                                return "சரியான விளக்கத்தை உள்ளிடவும்";
                                            }

                                            if (!tamilRegex.test(value)) {
                                                return "தமிழ் எழுத்துக்களுக்கு மட்டுமே அனுமதி";
                                            }

                                            return true;
                                        }
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="விளக்கம் *"
                                            placeholder="தயவுசெய்து தமிழ் விளக்கத்தை உள்ளிடவும்"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            multiline
                                            rows={4}
                                            size='small'
                                            inputProps={{ maxLength: 500 }}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.tamilDescription && errors.tamilDescription.message}
                                </FormHelperText>
                                <FormHelperText sx={{ textAlign: 'end' }}>
                                    அதிகபட்சம் 500 எழுத்துகள்.
                                </FormHelperText>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        {getValues('logoUrl') ? (
                            <>
                                <div className="input_background">
                                    <p className="mb-2">Uploaded Logo<span className="text-danger ml-1">*</span></p>
                                    <div className="row">
                                        <Grid item lg={6} md={6} sm={12} xs={12} className="mb-2 text-center">
                                            <Controller
                                                name="logoName"
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: "Logo is required" }}
                                                render={({ field }) => (
                                                    <Button
                                                        component="label"
                                                        variant="contained"
                                                        color="success"
                                                        {...field}
                                                        sx={{ width: '90%', fontSize: '11px', textTransform: 'none', padding: '8px 0px' }}
                                                        onChange={(e) =>
                                                            handleFileUpload(e, "logo", "logoName", "logoUrl", 'image')
                                                        }
                                                        startIcon={<CloudDoneIcon />}
                                                        href="#file-upload"
                                                    >
                                                        Uploaded ( Click to Change)
                                                        <VisuallyHiddenInput type="file" />
                                                    </Button>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} className="mb-2">
                                            <Button
                                                className="c-pointer ml-2"
                                                size="medium"
                                                sx={{ width: '90%' }}
                                                variant="outlined"
                                                onClick={() =>
                                                    handleClickOpen(
                                                        getValues("logo"),
                                                        getValues("logoUrl"),
                                                    )
                                                }
                                            >
                                                Preview
                                            </Button>
                                        </Grid>
                                    </div>
                                    <div>
                                        <span className="font-sm d-flex  mt-1 ">
                                            Recommended Image Size 364 X 55 (Px)<br />
                                            Max size upto 5mb <br />
                                            Accepted Format: jpeg, jpg, png
                                            <span className="text-danger">*</span>
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="input_background">
                                <Controller
                                    name="logoName"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Logo is required" }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Upload Logo *"
                                            placeholder="Upload Logo"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            disabled
                                            size='small'
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.logoName && errors.logoName.message}
                                </FormHelperText>
                                <div className="d-flex align-items-center w-100 my-3">
                                    <p className="font-sm">
                                        Recommended Image Size 364 X 55 (Px)<br />
                                        Max size upto 5mb <br />
                                        Accepted Format: jpeg, jpg, png
                                    </p>
                                    <label
                                        className="ml-auto lbl_btn"
                                        onChange={(e) =>
                                            handleFileUpload(e, "logo", "logoName", "logoUrl", 'image')
                                        }
                                        href="#file-upload"
                                    >
                                        <img
                                            src="/images/admin/upload.png"
                                            className="mr-3"
                                            alt=""
                                        />
                                        Upload
                                        <VisuallyHiddenInput type="file" />
                                    </label>
                                </div>
                            </div>
                        )}
                    </Grid>
                </Grid>
                <div className='text-center mt-5 mb-3'>
                    <Button onClick={() => handleCloseModal()} className="discard-btn mr-3" variant="outlined">Close</Button>
                    <Button type="submit" variant='contained' className='publish-button'>{actions === 'update' ? "Update" : "Save"}</Button>
                </div>
            </form>
            <Viewimage
                viewImage={viewImage}
                viewImageName={viewImageName}
                open={open}
                handleClose={handleimgClose}
            />
        </Box>
    )
}

export default Awardsandrecognitionsadd
