import { Box } from '@mui/material';
import React, { useContext, useState } from 'react'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Awardsandrecognitionslist from './Awards-recognitions-page/Awardsandrecognitionslist';
import DisplayLogo from './DisplayLogo-page/DisplayLogo';
import MapList from './Map-page/MapList';
import DataContext from '../../../context/DataContext';

const HomePageLayout = () => {
    const { hasPrivilege } = useContext(DataContext);
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ marginTop: '10px' }}>
            <Box sx={{ width: '100%' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" >
                        {hasPrivilege("72") && (  <Tab label="Awards and Recognitions" value="1" sx={{
                                '&.Mui-selected': {
                                    background: 'none !important',
                                    color: '#000 !important',
                                    borderRadius: '0px !important',
                                    borderBottom: '3px solid #083fb2 !important',
                                },
                            }} />)}
                           {hasPrivilege("79") && ( <Tab label="Display Logo" value="2" sx={{
                                '&.Mui-selected': {
                                    background: 'none !important',
                                    color: '#000 !important',
                                    borderRadius: '0px !important',
                                    borderBottom: '3px solid #083fb2 !important',
                                },
                            }} />)}
                            {hasPrivilege("76") && ( <Tab label="Map" value="3" sx={{
                                '&.Mui-selected': {
                                    background: 'none !important',
                                    color: '#000 !important',
                                    borderRadius: '0px !important',
                                    borderBottom: '3px solid #083fb2 !important',
                                },
                            }} />)}
                        </TabList>
                    </Box>
                    {hasPrivilege("72") && (  <TabPanel value="1"><Awardsandrecognitionslist/></TabPanel>)}
                    {hasPrivilege("79") && (<TabPanel value="2"><DisplayLogo/></TabPanel>)}
                    {hasPrivilege("76") && ( <TabPanel value="3"><MapList/></TabPanel>)}
                </TabContext>
            </Box>
        </Box>
    )
}

export default HomePageLayout
