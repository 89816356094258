import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import {
  Autocomplete,
  Container,
  Grid,
  Pagination,
  Stack,
  Tabs,
  TextField,
} from "@mui/material";
import { Typewriter } from "react-simple-typewriter";
import Navbar from "../navbar-page/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../footer-page/Footer";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
const ExplorePage = ({ path }) => {
  const { navigator, setPageLoading, expolreId } = useContext(DataContext);
  const { name, id } = useParams();

  let ids;
  if (id) {
    ids = atob(id);
    console.log(ids);
  }
  const [serviceId, setServiceId] = useState(ids ? ids : 0);
  let tabNames;
  if (name) {
    tabNames = name;
    // console.log(tabNames)
  }
  const navigate = useNavigate();
  const [tabName, setTabName] = useState("");
  const [buttonText, setButtonText] = useState("Explore");
  const [tabDetails, setTabDetails] = useState("");
  const [tabIndex, setTabIndex] = useState(1);
  const [tabSerive, setTabSerive] = useState([]);
  const [tabDetailsList, setTabDetailsList] = useState([]);
  const [sectorId, setSectorId] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [depId, setDepId] = useState("");
  const [page, setPage] = useState(1);
  const [stages, setStages] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedStages, setSelectedStages] = useState("");
  const [stageId, setStageId] = useState([]);
  const [cateValue, setCateValue] = useState("");
  const [cateId, setCateId] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const tabsContainerRef = useRef(null);
  let token = localStorage.getItem("jwttoken");
  let useId = localStorage.getItem("userId")

  useDidMountEffect(() => {
    getExploreApi();
    // console.log(name);
  }, [name, sectorId, serviceId, stageId, depId, cateValue, page]);

  useDidMountEffect(() => {
    departmentdropdownList();
    sectorsdropdownApi();
    stagesdropdownApi();
    categoryApi();
    tabApi();
  }, []);

  // useEffect(() => {
  //   if(tabNames){

  //     handleTabDetails(tabNames)
  //   }
  // }, [tabNames])

  const tabApi = () => {
    apiService(`home/service/list`, "", "get")
      .then((result) => {
        setPageLoading(false);
        if (result.data) {
          setTotalPage(result.data.totalPage);
          if (result.data.services) {
            setTabDetailsList(result.data.services);
            if (name) {
              const index = result.data.services.findIndex(
                (element) => element.service === name
              );
              if (index !== -1) {
                handleTabDetails(serviceId, name, index);
              }
            }
          }
        }
      })
      .catch((err) => { console.log(err);
      });
  };

  const getExploreApi = () => {
    setTabSerive([]);
    setPageLoading(true);
    var req = {
      listSize: 9,
      pageNumber: page,
      serviceId: serviceId,
      //  serviceId: 1,
      sectorIds: sectorId,
      stageIds: stageId,
      departmentId: depId,
      specialCategory: cateValue,
    };
    console.log(req);

    apiService(`home/project/list`, req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setTotalPage(result.data.totalPages);
          if (result.data.projects) {
            setTabSerive(result.data.projects);
          }
        }
      })
      .catch((err) => { });
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleChange = (event, newValue) => {
    const newTabDetails = tabDetailsList[newValue - 1];

    if (newTabDetails) {
      setTabName(newTabDetails.name);
      setButtonText("Explore");
      setPageTitle(newTabDetails.name);
      setTabDetails(newTabDetails);
      setTabSerive(newTabDetails.serviceList);

   

      if (tabRefs.current[newValue - 1]) {
        tabRefs.current[newValue - 1].scrollIntoView({
          behavior: "smooth",
          inline: "center",
        });
      }
    }
  };

  useEffect(() => {
    if (name && tabDetailsList.length > 0) {
      const index = tabDetailsList.findIndex((tab) => tab.service === name);
      if (index !== -1) {
        handleTabDetails(tabDetailsList[index].id, name, index);
      }
    }
  }, [name, tabDetailsList]);

  const firstLetter = tabDetails.charAt(0);
  const remainingLetters = tabDetails.slice(1);

  const handleTabDetails = (id, value, index) => {
    setServiceId(id);
    setTabIndex(index);
    setPage(1);
    setTabDetails(value);
    setPageTitle(value);

    if (tabRefs.current[index]) {
      tabRefs.current[index].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
      console.log(`Scrolling tab ${index} into view.`);
    } else {
      console.warn(`No ref found for tab ${index}`);
    }

    if (tabsContainerRef.current) {
      console.log("Attempting to scroll the container manually.");
      tabsContainerRef.current.scrollTo({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  const tabRefs = useRef([]);


  const [sectors, setSectors] = useState([]);
  const sectorsdropdownApi = () => {
    apiService("home/sector/list", "", "unauthget")
      .then((result) => {
        if (result && result.data && result.data.sectors) {
          setSectors(result.data.sectors);
        }
      })
      .catch((err) => { });
  };
  const handleRouteValue = (path) => {
    if (path !== "") {
      navigator(path);
    }
  };

  const [sectorValue, setSectorValue] = useState([]);
  const handleSelectSector = (event, selectedOptions) => {
    let ids = [];
    if (selectedOptions !== "") {
      setSectorValue(selectedOptions || []);
      selectedOptions?.forEach((name) => {
        sectors?.forEach((val) => {
          if (name === val?.name) {
            ids?.push(val.id.toString());
          }
        });
      });
      setSectorId(ids);
      // console.log(ids);
    } else {
      setSectorValue("");
      setSectorId([]);
    }
  };

  const departmentdropdownList = () => {
    apiService(`home/department/list`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data) {
              var values = result.data;
              setDepartmentList(values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [departmentName, setDepartmentName] = useState("");
  const handleSelectDepartment = (value) => {
    setDepartmentName(value);
    const dep = departmentList.filter((val) => val.name === value);
    if (value !== null) {
      setDepId(dep[0].id);
    } else {
      setDepId("");
    }
  };
  const [categoryList, setCategoryList] = useState([]);
  const categoryApi = () => {
    apiService(
      `admin/dropdownvalues/get?key=${"specialcategories"}`,
      "",
      "unauthget"
    )
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.dropDownValues &&
          result.data.dropDownValues.values
        ) {
          setCategoryList(result.data.dropDownValues.values);
        }
      })
      .catch((err) => { });
  };

  const handleCategories = (value) => {
    setCateValue(value);

  };

  const stagesdropdownApi = () => {
    apiService("stage/list", "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data) {
              setStages(result.data);
            }
          }
        }
      })
      .catch((err) => { 
        console.log(err);
        
      });
  };

  const handleStages = (value) => {
    setSelectedStages(value);
    let sec = [];
    if (value !== "") {
      setSelectedStages(value || []);
      value?.forEach((a) => {
        // if (name === val?.name) {
        sec?.push(a.id.toString());
        // }
      });
      setStageId(sec);
      // console.log(sec);
    } else {
      setSelectedStages("");
      setStageId([]);
    }
  };
  // const handlenow = () => {
  //   navigate('/login')
  // }
  const handleRedirect = (url) => {
    if (url !== undefined) {
      const fullUrl = /^https?:\/\//.test(url) ? url : `https://${url}`;

      window.open(fullUrl, "_blank");
    }
  };
  const handleRegister = (val, url) => {

    if (token) {
      var req = {
        userId: Number(useId),
        projectId: val
      }

      apiService(`project/count`, req, "post");

      if (url !== undefined) {
        const fullUrl = /^https?:\/\//.test(url) ? url : `https://${url}`;

        window.open(fullUrl, "_blank");
      }
    }
    else {
      navigator('/login')
    }
  };
  return (
    <div className="back-color">
      <Navbar />
      <div className="back-layer"></div>
      <div className="back-layer1"></div>
      {/* <div className="top-layer"></div> */}
      <div className="set_z-ind">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Container>
              <div className="main-title">
                <div className="vectorimg-new">
                  <img src={"/images/Vector (1).png"} alt="vector" />
                </div>
                <p className="big-title2 mb-0 mt-5">
                  <span className="big-title2 ml-2">
                    Startup
                    <div className="anim-div">
                      <div className="typewriter-container">
                        <div className="anim-abs">
                          <span>{buttonText}</span>
                        </div>
                        <div className="chipTextml-1">
                          <span>{firstLetter}</span>
                          <Typewriter
                            words={[remainingLetters]}
                            loop={false}
                            cursor
                            cursorStyle={<span className="cursor-style"></span>}
                            cursorBlinking={false}
                            typeSpeed={250}
                            deleteSpeed={60}
                            delaySpeed={1000}
                            style={{
                              background: "#B6CBFB",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </span>
                </p>
                <p className="titles-paras mt-3">
                  We Provide Our {tabDetails} Services Across The Entire
                  Ecosystem. Here Is Our Directory Of Services Based On Our
                  Established Policies. Search For Quick Services To Explore And
                  Avail Of Them Hassle-Free.
                </p>
              </div>
            </Container>
          </Grid>
        </Grid>

        <TabContext className="tab-div">
          <div className="name-tab">
            <Box className="tabs-boxs" ref={tabsContainerRef} sx={{maxWidth:'75% !important'}}>
              <Tabs
                // value={value}
                value={tabIndex}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                ref={tabsContainerRef}
              >
                {tabDetailsList.map((menu, index) => (
                  <Tab
                    key={index}
                    label={`0${index + 1}. ${menu.service}`}
                    // onClick={(e) => handleTabDetails(menu.id, menu.service, index)}
                    className={tabIndex === index ? "Mui-selected" : ""}
                    data-value={index}
                    // onClick={() => handleTabDetails(menu.id, menu.service, index)}
                    onClick={() => {
                      // setTabIndex(null);
                      handleTabDetails(menu.id, menu.service, index);
                    }}
                    ref={(el) => (tabRefs.current[index] = el)}
                  />
                ))}
              </Tabs>
            </Box>
          </div>
        </TabContext>
        <div className="new-design-lists">
          <Container>
            <div className="eco-filters">
              <div
                className="filter-btns c-pointer"
                onClick={() => setShowForm(!showForm)}
              >
                <span>
                  <img src={"/images/filtericn.png"} alt="filtericn" />
                </span>
                <p className="filter-text">Filters</p>
              </div>
              {showForm && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Autocomplete
                      multiple={true}
                      limitTags={1}
                      // disableClearable
                      options={sectors.map((list) => list.name)}
                      value={sectorValue}
                      onChange={handleSelectSector}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sectors"
                          placeholder="Select Sectors"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Autocomplete
                      disablePortal
                      multiple={true}
                      options={stages}
                      getOptionLabel={(option) => option.name}
                      value={selectedStages ? selectedStages : []}
                      onChange={(event, newValue) => handleStages(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Stages"
                          placeholder="Select Stages"
                        />
                      )}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Autocomplete
                      disablePortal
                      value={departmentName}
                      options={departmentList.map((option) => option.name)}
                      onChange={(event, newValue) =>
                        handleSelectDepartment(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Department"
                          placeholder="Select Department"
                        />
                      )}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Autocomplete
                      disablePortal
                      value={cateValue}
                      options={categoryList}
                      onChange={(event, newValue) => handleCategories(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Special Category"
                          placeholder="Select Special Category"
                        />
                      )}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                </Grid>
              )}
            </div>
            <div className="event-title-line my-4">
              <h2 className="event-titles-new mt-3">{tabDetails}</h2>
              <div className="event-line-img bottom-down-arrow">
                <img src="/images/Vector (1).png" alt="LINE" />
              </div>
            </div>
          </Container>
          {/* {
            (pageTitle ===  id) && ( */}
          <>
            {tabSerive && tabSerive.length > 0 ? (
              <div className="new-tab-crd mb-4">
                <Container>
                  {/* <h4 className="color-blue my-5">Suggested Projects </h4> */}
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    spacing={5}
                  >
                    {tabSerive &&
                      tabSerive.map((list, index) => (
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className="service_data" key={index}>
                              <div className="card-image">
                                <img
                                  src={
                                    list.imageName
                                      ? list.imageName
                                      : "/images/oneTn/onetn_fav.png"
                                  }
                                  alt="OneTn_img"
                                  className="imag-pink"
                                />
                              </div>
                              <h4>{list.name}</h4>
                              <h5>{list.departmentName ? list.departmentName : ""}</h5>
                              <div className="chip-home-service"></div>
                              <div className="home-Services-para color-gray">
                                <p style={{wordBreak:'break-word'}}>{list.brief}</p>
                              </div>
                              {list.tags && (
                                <>
                                  <div className="tag-container">
                                    <span className="tag-line">Tags</span>
                                  </div>
                                  <div className="tags">
                                    {list.tags &&
                                      list.tags.map((tag, i) => (
                                        <span key={i}>{tag}</span>
                                      ))}
                                  </div></>
                              )}
                              <div className="d-flex align-items-start justify-content-center">
                                <div>
                                  <div
                                    className="c-pointer"
                                    onClick={() => handleRegister(list.id, list.redirecturl)}
                                  >

                                    <span className="know-more">
                                      {" "}
                                      Know More{" "}
                                    </span>

                                    <span className="ml-2">
                                      <IoArrowForwardCircleOutline className="know-arrows" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="text-right">
                                    <button className="arrow-btn">
                                      <ArrowForwardIcon onClick={() => handleRouteValue(list.redirectPath)} />
                                    </button>
                                  </div> */}
                            </div>
                          </Grid>
                        </>
                      ))}
                  </Grid>
                  {totalPage > 1 && (
                    <Grid item sm={12} md={12} lg={12}>
                      <div className="event-pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={totalPage}
                            page={page}
                            onChange={handlePageChange}
                          />
                        </Stack>
                      </div>
                    </Grid>
                  )}
                </Container>
              </div>
            ) : (
              <Container>
                <div className="text-center w-100 my-5">
                  <h5 className="mb-4">
                    Currently we couldn't find the related projects.
                  </h5>
                  <div>
                    <img
                      src="/images/oneTn/black_oneTn.png"
                      alt="oneTn_logo_high.svg"
                      className="logo_dash1 img-fluid"
                    />
                  </div>
                  <div className="mt-5">
                    <h3 className="title_p opa">{/* StartupTN 3.0 */}</h3>
                  </div>
                </div>
              </Container>
            )}
          </>
        
        </div>
       
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default ExplorePage;
