import { Box, Button, DialogActions, FormHelperText, Grid, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import notifyService from '../../../../api/notifySerivce';
import DataContext from '../../../../context/DataContext';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import Viewimage from '../../../../api/Viewimage';
import apiService from '../../../../api/apiService';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const Mapadd = ({ handleCloseModal, actions, mapDataId1, mapDataId2, listApi }) => {
  useDidMountEffect(() => {
    if (actions === "update") {
      getApi()
    }
  }, [])
  const { setPageLoading, isWhitespace, validateNumberonly } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });

  const getApi = () => {
    setPageLoading(true)
    apiService(`district/admin/get?id1=${mapDataId1}&id2=${mapDataId2}`, '', 'get')
      .then((res) => {
        setPageLoading(false)
        setFormData(res?.data)
      }).catch((err) => {
        console.log(err);
      });
  }
  const onSubmit = (data) => {
    console.log(data);
    var req = {
      ...data,
    };

    if (actions === "update") {
      req.id1 = mapDataId1;
      req.id2 = mapDataId2;
    }

    debugger
    setPageLoading(true)
    apiService('district/admin/save', req, 'post')
      .then((res) => {
        console.log(res);
        if (res) {
          if (res.data.responseStatus === "Success") {
            handleCloseModal();
            notifyService("success", "Success", "Saved Sucessfully");
            listApi()
            reset()
          }
        }
        handleCloseModal()
      }).catch((err) => {
        console.log(err);

      });

  }
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService(
      "danger",
      "Submit Failed",
      "Please check the mandatory fields"
    );
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container spacing={3} sx={{ marginTop: '5px' }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="input_background mb-2">
              <p>English</p>
              <div className="mb-3">
                <Controller
                  name="district"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "District is required", validate: {
                      noWhitespace: value => !isWhitespace(value) || 'Please enter valid District',
                      onlyEnglish: value => /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>_\-+=\[\]\/\\;:'`~\s]*$/.test(value) || 'Only English letters are allowed'

                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="District *"
                      placeholder="Please Enter District"
                      fullWidth
                      type="text"
                      {...field}
                      size='small'
                      disabled={actions === 'update'}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.district && errors.district.message}
                </FormHelperText>
              </div>
              <div className="mb-3">
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Description is required", validate: {
                      noWhitespace: value => !isWhitespace(value) || 'Please enter valid Description',
                      onlyEnglish: value => /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>_\-+=\[\]\/\\;:'`~\s]*$/.test(value) || 'Only English letters are allowed'

                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label=" Description *"
                      placeholder="Please Enter Description"
                      fullWidth
                      type="text"
                      {...field}
                      multiline
                      rows={4}
                      size='small'
                      inputProps={{ maxLength: 500 }}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.description && errors.description.message}
                </FormHelperText>
                <FormHelperText sx={{ textAlign: 'end' }}>
                  Max 500 characters.
                </FormHelperText>
              </div>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="input_background mb-2">
              <p>தமிழ்</p>
              <div className='mb-3'>
                <Controller
                  name="districtTamil"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "மாவட்டம் தேவை",
                    validate: (value) => {
                      const tamilRegex = /^[\u0B80-\u0BFF\d\s!@#$%^&*()_+=\[\]{};':"\\|,.<>/?]*$/;

                      if (isWhitespace(value)) {
                        return "சரியான மாவட்டத்தை உள்ளிடவும்";
                      }

                      if (!tamilRegex.test(value)) {
                        return "தமிழ் எழுத்துக்களுக்கு மட்டுமே அனுமதி";
                      }

                      return true;
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="மாவட்டம் *"
                      placeholder="மாவட்டத்தை உள்ளிடவும்"
                      fullWidth
                      type="text"
                      {...field}
                      size='small'
                      disabled={actions === 'update'}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.districtTamil && errors.districtTamil.message}
                </FormHelperText>
              </div>
              <div className='mb-3'>
                <Controller
                  name="tamilDescription"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "விளக்கம் தேவை",
                    validate: (value) => {
                      const tamilRegex = /^[\u0B80-\u0BFF\d\s!@#$%^&*()_+=\[\]{};':"\\|,.<>/?]*$/;

                      if (isWhitespace(value)) {
                        return "சரியான விளக்கத்தை உள்ளிடவும்";
                      }

                      if (!tamilRegex.test(value)) {
                        return "தமிழ் எழுத்துக்களுக்கு மட்டுமே அனுமதி";
                      }

                      return true;
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="விளக்கம் *"
                      placeholder="தயவுசெய்து தமிழ் விளக்கத்தை உள்ளிடவும்"
                      fullWidth
                      type="text"
                      {...field}
                      multiline
                      rows={4}
                      size='small'
                      inputProps={{ maxLength: 500 }}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.tamilDescription && errors.tamilDescription.message}
                </FormHelperText>
                <FormHelperText sx={{ textAlign: 'end' }}>
                  அதிகபட்சம் 500 எழுத்துகள்.
                </FormHelperText>
              </div>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="input_background">
              <p>Details</p>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className='mb-3'>
                    <Controller
                      name="startups"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Startups is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Startups*"
                          placeholder="Please Enter Startups"
                          fullWidth
                          type="text"
                          {...field}
                          size='small'
                          inputProps={{ maxLength: 8 }}
                          onKeyDown={(e) => validateNumberonly(e)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.startups && errors.startups.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className='mb-3'>
                    <Controller
                      name="universities"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Educational Institutions is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Educational Institutions *"
                          placeholder="Please Enter Educational Institutions"
                          fullWidth
                          type="text"
                          {...field}
                          size='small'
                          inputProps={{ maxLength: 8 }}
                          onKeyDown={(e) => validateNumberonly(e)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.universities && errors.universities.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className='mb-3'>
                    <Controller
                      name="incubationCenter"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Incubation Centers is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Incubation Centers *"
                          placeholder="Please Enter Incubation Centers"
                          fullWidth
                          type="text"
                          {...field}
                          size='small'
                          inputProps={{ maxLength: 8 }}
                          onKeyDown={(e) => validateNumberonly(e)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.incubationCenter && errors.incubationCenter.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className='mb-3'>
                    <Controller
                      name="corporates"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Corporates is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Corporates *"
                          placeholder="Please Enter Corporates"
                          fullWidth
                          type="text"
                          {...field}
                          size='small'
                          inputProps={{ maxLength: 8 }}
                          onKeyDown={(e) => validateNumberonly(e)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.corporates && errors.corporates.message}
                    </FormHelperText>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div className='text-center mt-5 mb-3'>
          <Button onClick={() => handleCloseModal()} className="discard-btn mr-3" variant="outlined">Close</Button>
          <Button type="submit" variant='contained' className='publish-button'>{actions === "update" ? "Update" : "Save"}</Button>
        </div>
      </form>
    </Box>
  )
}

export default Mapadd

