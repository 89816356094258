import React, { useState } from "react";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import { Container, Grid } from "@mui/material";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";
import DataContext from "../../../context/DataContext";
import { useContext } from "react";

const MapSection = () => {
  const { t } = useContext(DataContext);
  const lang = localStorage.getItem("lang");
  useDidMountEffect(() => {
    mapGetApi();
  }, [lang]);
  const [districtMapDetails, setDistrictMapDetails] = useState([]);
  const [selectedHub, setselectedHub] = useState({});
  const mapGetApi = () => {
    var req = {
      lang: lang === "en" ? "ENG" : "TAM",
    };
    apiService("home/district/list1", req, "unauthpost")
      .then((result) => {
        if (result && result.data && result.data.districts) {
          setDistrictMapDetails(result.data.districts);
          let selectDIS =
            lang === "en" ? selectedDistrict : selectedTamilDistrict;
          let defauldDIS = lang === "en" ? "chennai" : "சென்னை";
          const filtered = result.data.districts.filter((item) =>
            item.name.toLowerCase().includes(selectDIS ? selectDIS : defauldDIS)
          );
          setselectedHub(filtered[0]);
          setLangName(lang === "en" ? selectedDistrict : selectedTamilDistrict)
          // result.data.hubs.forEach(element => {

          //     if (element.name === 'chennai') {
          //         setselectedHub(element)
          //     }
          // });
        }
      })
      .catch((err) => { });
  };
  const [selectedDistrict, setSelectedDistrict] = useState("chennai");
  const [selectedTamilDistrict, setSelectedTamilDistrict] = useState("சென்னை");
  const [districtName, setDistrictName] = useState("chennai");
  const [districtTamilName, setDistrictTamilName] = useState("சென்னை");
  const [langName, setLangName] = useState(
    lang === "en" ? "chennai" : "சென்னை"
  );
  const handleDistrict = (value, tamValue) => {
    setSelectedDistrict(value);
    setSelectedTamilDistrict(tamValue);
    setDistrictName(value);
    setDistrictTamilName(tamValue);
    setLangName(lang === "en" ? value : tamValue);
    const filtered = districtMapDetails.filter((item) =>
      item.name.toLowerCase().includes(lang === "en" ? value.toLowerCase() : tamValue)
    );
    setselectedHub(filtered[0]);
    // debugger
    // districtMapDetails.forEach(element => {

    //     if (element.name === value) {
    //         setselectedHub(element)
    //     }
    // });
  };
  const handleMouseEnter = (value, tamValue) => {
    setSelectedDistrict(value);
    setSelectedTamilDistrict(tamValue);
    setLangName(lang === "en" ? value : tamValue);
  };
  const handleMouseLeave = () => {
    setSelectedDistrict(districtName);
    setSelectedTamilDistrict(districtTamilName);
    setLangName(lang === "en" ? districtName : districtTamilName);
  };
  return (
    <section className="presence-section">
      <div className="presence">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Container>
              <div className="map-div">
                <div className="map-div-bg">
                  <img
                    src="/images/map/map-bg.png"
                    alt=""
                    className="img-fluid map-bg"
                  />
                  <img
                    src="/images/map/Thiruvallur.png"
                    alt=""
                    className="img-fluid Thiruvallur"
                    onMouseEnter={() =>
                      handleMouseEnter("tiruvallur", "திருவள்ளூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Chennai.png"
                    alt=""
                    className="img-fluid Chennai c-pointer"
                    onMouseEnter={() => handleMouseEnter("chennai", "சென்னை")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Chengalpattu.png"
                    alt=""
                    className="img-fluid Chengalpattu"
                    onMouseEnter={() =>
                      handleMouseEnter("chengalpattu", "செங்கல்பட்டு")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Kancheepuram.png"
                    alt=""
                    className="img-fluid Kancheepuram"
                    onMouseEnter={() =>
                      handleMouseEnter("kancheepuram", "காஞ்சிபுரம்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Viluppuram.png"
                    alt=""
                    className="img-fluid Viluppuram"
                    onMouseEnter={() =>
                      handleMouseEnter("viluppuram", "விழுப்புரம்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />

                  <img
                    src="/images/map/Ranipet.png"
                    alt=""
                    className="img-fluid Ranipet"
                    onMouseEnter={() =>
                      handleMouseEnter("ranipet", "ராணிப்பேட்டை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Vellore.png"
                    alt=""
                    className="img-fluid Vellore"
                    onMouseEnter={() => handleMouseEnter("vellore", "வேலூர்")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Tiruvannamalai.png"
                    alt=""
                    className="img-fluid Tiruvannamalai"
                    onMouseEnter={() =>
                      handleMouseEnter("tiruvannamalai", "திருவண்ணாமலை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Tirupathur.png"
                    alt=""
                    className="img-fluid Tirupathur"
                    onMouseEnter={() =>
                      handleMouseEnter("tirupattur", "திருப்பத்தூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Krishnagiri.png"
                    alt=""
                    className="img-fluid Krishnagiri c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("krishnagiri", "கிருஷ்ணகிரி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Kallakurichi.png"
                    alt=""
                    className="img-fluid Kallakurichi"
                    onMouseEnter={() =>
                      handleMouseEnter("kallakurichi", "கள்ளக்குறிச்சி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Cuddalore.png"
                    alt=""
                    className="img-fluid Cuddalore c-pointer"
                    onMouseEnter={() => handleMouseEnter("cuddalore", "கடலூர்")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Salem.png"
                    alt=""
                    className="img-fluid Salem c-pointer"
                    onMouseEnter={() => handleMouseEnter("salem", "சேலம்")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Erode.png"
                    alt=""
                    className="img-fluid Erode c-pointer"
                    onMouseEnter={() => handleMouseEnter("erode", "ஈரோடு")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Nilgiris.png"
                    alt=""
                    className="img-fluid Nilgiris"
                    onMouseEnter={() => handleMouseEnter("nilgiris", "நீலகிரி")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Thanjavur.png"
                    alt=""
                    className="img-fluid Thanjavur c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("thanjavur", "தஞ்சாவூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Tiruchirappalli.png"
                    alt=""
                    className="img-fluid Tiruchirappalli c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("tiruchirappalli", "திருச்சிராப்பள்ளி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Karur.png"
                    alt=""
                    className="img-fluid Karur"
                    onMouseEnter={() => handleMouseEnter("karur", "கரூர்")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Tiruppur.png"
                    alt=""
                    className="img-fluid Tiruppur"
                    onMouseEnter={() =>
                      handleMouseEnter("tiruppur", "திருப்பூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Coimbatore.png"
                    alt=""
                    className="img-fluid Coimbatore c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("coimbatore", "கோயம்புத்தூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Madurai.png"
                    alt=""
                    className="img-fluid Madurai c-pointer"
                    onMouseEnter={() => handleMouseEnter("madurai", "மதுரை")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Tirunelveli.png"
                    alt=""
                    className="img-fluid Tirunelveli c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("tirunelveli", "திருநெல்வேலி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Mayiladuthurai.png"
                    alt=""
                    className="img-fluid Mayiladuthurai"
                    onMouseEnter={() =>
                      handleMouseEnter("mayiladuthurai", "மயிலாடுதுறை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />

                  <img
                    src="/images/map/Ariyalur.png"
                    alt=""
                    className="img-fluid Ariyalur"
                    onMouseEnter={() =>
                      handleMouseEnter("ariyalur", "அரியலூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Perambalur.png"
                    alt=""
                    className="img-fluid Perambalur"
                    onMouseEnter={() =>
                      handleMouseEnter("perambalur", "பெரம்பலூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Dharmapuri.png"
                    alt=""
                    className="img-fluid Dharmapuri"
                    onMouseEnter={() =>
                      handleMouseEnter("dharmapuri", "தருமபுரி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Tiruvarur.png"
                    alt=""
                    className="img-fluid Tiruvarur"
                    onMouseEnter={() =>
                      handleMouseEnter("thiruvarur", "திருவாரூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Nagapattinam.png"
                    alt=""
                    className="img-fluid Nagapattinam"
                    onMouseEnter={() =>
                      handleMouseEnter("nagapattinam", "நாகப்பட்டினம்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Namakkal.png"
                    alt=""
                    className="img-fluid Namakkal"
                    onMouseEnter={() =>
                      handleMouseEnter("namakkal", "நாமக்கல்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Dindigul.png"
                    alt=""
                    className="img-fluid Dindigul"
                    onMouseEnter={() =>
                      handleMouseEnter("dindigul", "திண்டுக்கல்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Pudukkottai.png"
                    alt=""
                    className="img-fluid Pudukkottai"
                    onMouseEnter={() =>
                      handleMouseEnter("pudukottai", "புதுக்கோட்டை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Theni.png"
                    alt=""
                    className="img-fluid Theni"
                    onMouseEnter={() => handleMouseEnter("theni", "தேனி")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Sivagangai.png"
                    alt=""
                    className="img-fluid Sivagangai"
                    onMouseEnter={() =>
                      handleMouseEnter("sivagangai", "சிவகங்கை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Ramanathapuram.png"
                    alt=""
                    className="img-fluid Ramanathapuram"
                    onMouseEnter={() =>
                      handleMouseEnter("ramanathapuram", "ராமநாதபுரம்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Virudhunagar.png"
                    alt=""
                    className="img-fluid Virudhunagar"
                    onMouseEnter={() =>
                      handleMouseEnter("virudhunagar", "விருதுநகர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Tenkasi.png"
                    alt=""
                    className="img-fluid Tenkasi"
                    onMouseEnter={() => handleMouseEnter("tenkasi", "தென்காசி")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Thoothukudi.png"
                    alt=""
                    className="img-fluid Thoothukudi"
                    onMouseEnter={() =>
                      handleMouseEnter("thoothukudi", "தூத்துக்குடி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Kanyakumari.png"
                    alt=""
                    className="img-fluid Kanyakumari"
                    onMouseEnter={() =>
                      handleMouseEnter("kanniyakumari", "கன்னியாகுமரி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />

                  {/* selected Map Design */}
                  <img
                    src="/images/map/selected_map/KanniyaKumari.png"
                    alt=""
                    className="img-fluid Kanyakumari_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "kanniyakumari" : "கன்னியாகுமரி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("kanniyakumari", "கன்னியாகுமரி")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("kanniyakumari", "கன்னியாகுமரி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Thoothukudi.png"
                    alt=""
                    className="img-fluid Thoothukudi_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "thoothukudi" : "தூத்துக்குடி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("thoothukudi", "தூத்துக்குடி")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("thoothukudi", "தூத்துக்குடி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Tenkasi.png"
                    alt=""
                    className="img-fluid Tenkasi_dist c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "tenkasi" : "தென்காசி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("tenkasi", "தென்காசி")}
                    onMouseEnter={() => handleMouseEnter("tenkasi", "தென்காசி")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Virudhunagar.png"
                    alt=""
                    className="img-fluid Virudhunagar_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "virudhunagar" : "விருதுநகர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("virudhunagar", "விருதுநகர்")}
                    onMouseEnter={() =>
                      handleMouseEnter("virudhunagar", "விருதுநகர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Ramanathapuram.png"
                    alt=""
                    className="img-fluid Ramanathapuram_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "ramanathapuram" : "ராமநாதபுரம்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("ramanathapuram", "ராமநாதபுரம்")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("ramanathapuram", "ராமநாதபுரம்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Sivagangai.png"
                    alt=""
                    className="img-fluid Sivagangai_dist c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "sivagangai" : "சிவகங்கை")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("sivagangai", "சிவகங்கை")}
                    onMouseEnter={() =>
                      handleMouseEnter("sivagangai", "சிவகங்கை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Theni.png"
                    alt=""
                    className="img-fluid Theni_dist c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "theni" : "தேனி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("theni", "தேனி")}
                    onMouseEnter={() => handleMouseEnter("theni", "தேனி")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/pudukottai.png"
                    alt=""
                    className="img-fluid Pudukkottai_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "pudukottai" : "புதுக்கோட்டை")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("pudukottai", "புதுக்கோட்டை")}
                    onMouseEnter={() =>
                      handleMouseEnter("pudukottai", "புதுக்கோட்டை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Dindigul.png"
                    alt=""
                    className="img-fluid Dindigul_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "dindigul" : "திண்டுக்கல்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("dindigul", "திண்டுக்கல்")}
                    onMouseEnter={() =>
                      handleMouseEnter("dindigul", "திண்டுக்கல்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Namakkal.png"
                    alt=""
                    className="img-fluid Namakkal_dist c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "namakkal" : "நாமக்கல்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("namakkal", "நாமக்கல்")}
                    onMouseEnter={() =>
                      handleMouseEnter("namakkal", "நாமக்கல்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Nagapatinam.png"
                    alt=""
                    className="img-fluid Nagapattinam_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "nagapattinam" : "நாகப்பட்டினம்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("nagapattinam", "நாகப்பட்டினம்")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("nagapattinam", "நாகப்பட்டினம்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Thiruvarur.png"
                    alt=""
                    className="img-fluid Tiruvarur_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "thiruvarur" : "திருவாரூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("thiruvarur", "திருவாரூர்")}
                    onMouseEnter={() =>
                      handleMouseEnter("thiruvarur", "திருவாரூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Dharmapuri.png"
                    alt=""
                    className="img-fluid Dharmapuri_dist c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "dharmapuri" : "தருமபுரி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("dharmapuri", "தருமபுரி")}
                    onMouseEnter={() =>
                      handleMouseEnter("dharmapuri", "தருமபுரி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Perambalur.png"
                    alt=""
                    className="img-fluid Perambalur_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "perambalur" : "பெரம்பலூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("perambalur", "பெரம்பலூர்")}
                    onMouseEnter={() =>
                      handleMouseEnter("perambalur", "பெரம்பலூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Ariyalur.png"
                    alt=""
                    className="img-fluid Ariyalur_dist c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "ariyalur" : "அரியலூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("ariyalur", "அரியலூர்")}
                    onMouseEnter={() =>
                      handleMouseEnter("ariyalur", "அரியலூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Mayiladuthurai.png"
                    alt=""
                    className="img-fluid Mayiladuthurai_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "mayiladuthurai" : "மயிலாடுதுறை")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("mayiladuthurai", "மயிலாடுதுறை")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("mayiladuthurai", "மயிலாடுதுறை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Villupuram.png"
                    alt=""
                    className="img-fluid Viluppuram_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "viluppuram" : "விழுப்புரம்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("viluppuram", "விழுப்புரம்")}
                    onMouseEnter={() =>
                      handleMouseEnter("viluppuram", "விழுப்புரம்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Tiruvannamalai.png"
                    alt=""
                    className="img-fluid Tiruvannamalai_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "tiruvannamalai" : "திருவண்ணாமலை")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("tiruvannamalai", "திருவண்ணாமலை")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("tiruvannamalai", "திருவண்ணாமலை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Kallakurichi.png"
                    alt=""
                    className="img-fluid Kallakurichi_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "kallakurichi" : "கள்ளக்குறிச்சி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("kallakurichi", "கள்ளக்குறிச்சி")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("kallakurichi", "கள்ளக்குறிச்சி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Nilgiris.png"
                    alt=""
                    className="img-fluid Nilgiris_dist c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "nilgiris" : "நீலகிரி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("nilgiris", "நீலகிரி")}
                    onMouseEnter={() => handleMouseEnter("nilgiris", "நீலகிரி")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Tiruppur.png"
                    alt=""
                    className="img-fluid Tiruppur_dist c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "tiruppur" : "திருப்பூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("tiruppur", "திருப்பூர்")}
                    onMouseEnter={() =>
                      handleMouseEnter("tiruppur", "திருப்பூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Karur.png "
                    alt=""
                    className="img-fluid Karur_dist c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "karur" : "கரூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("karur", "கரூர்")}
                    onMouseEnter={() => handleMouseEnter("karur", "கரூர்")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Tirupattur.png"
                    alt=""
                    className="img-fluid Tirupathur_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "tirupattur" : "திருப்பத்தூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("tirupattur", "திருப்பத்தூர்")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("tirupattur", "திருப்பத்தூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Ranipet.png"
                    alt=""
                    className="img-fluid Ranipet_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "ranipet" : "ராணிப்பேட்டை")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("ranipet", "ராணிப்பேட்டை")}
                    onMouseEnter={() =>
                      handleMouseEnter("ranipet", "ராணிப்பேட்டை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Vellore.png"
                    alt=""
                    className="img-fluid Vellore_dist c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "vellore" : "வேலூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("vellore", "வேலூர்")}
                    onMouseEnter={() => handleMouseEnter("vellore", "வேலூர்")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Tiruvallur.png"
                    alt=""
                    className="img-fluid Thiruvallur_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "tiruvallur" : "திருவள்ளூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("tiruvallur", "திருவள்ளூர்")}
                    onMouseEnter={() =>
                      handleMouseEnter("tiruvallur", "திருவள்ளூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Kanchepuram.png"
                    alt=""
                    className="img-fluid Kancheepuram_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "kancheepuram" : "காஞ்சிபுரம்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("kancheepuram", "காஞ்சிபுரம்")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("kancheepuram", "காஞ்சிபுரம்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/chengalpatu.png"
                    alt=""
                    className="img-fluid Chengalpattu_dist c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "chengalpattu" : "செங்கல்பட்டு")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("chengalpattu", "செங்கல்பட்டு")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("chengalpattu", "செங்கல்பட்டு")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Chennai.png"
                    alt=""
                    className="img-fluid Chennai_hub c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "chennai" : "சென்னை")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("chennai", "சென்னை")}
                    onMouseEnter={() => handleMouseEnter("chennai", "சென்னை")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/krishnagiri.png"
                    alt=""
                    className="img-fluid Krishnagiri c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "krishnagiri" : "கிருஷ்ணகிரி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("krishnagiri", "கிருஷ்ணகிரி")}
                    onMouseEnter={() =>
                      handleMouseEnter("krishnagiri", "கிருஷ்ணகிரி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Cuddalore.png"
                    alt=""
                    className="img-fluid Cuddalore_hub c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "cuddalore" : "கடலூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("cuddalore", "கடலூர்")}
                    onMouseEnter={() => handleMouseEnter("cuddalore", "கடலூர்")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/salem.png"
                    alt=""
                    className="img-fluid Salem_hub c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "salem" : "சேலம்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("salem", "சேலம்")}
                    onMouseEnter={() => handleMouseEnter("salem", "சேலம்")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Erode.png"
                    alt=""
                    className="img-fluid Erode_hub c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "erode" : "ஈரோடு")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("erode", "ஈரோடு")}
                    onMouseEnter={() => handleMouseEnter("erode", "ஈரோடு")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/coimbatore.png"
                    alt=""
                    className="img-fluid Coimbatore_hub c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "coimbatore" : "கோயம்புத்தூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("coimbatore", "கோயம்புத்தூர்")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("coimbatore", "கோயம்புத்தூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Trichy.png "
                    alt=""
                    className="img-fluid Tiruchirappalli_hub c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "tiruchirappalli" : "திருச்சிராப்பள்ளி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("tiruchirappalli", "திருச்சிராப்பள்ளி")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("tiruchirappalli", "திருச்சிராப்பள்ளி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/thanjavur.png"
                    alt=""
                    className="img-fluid Thanjavur_hub c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "thanjavur" : "தஞ்சாவூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("thanjavur", "தஞ்சாவூர்")}
                    onMouseEnter={() =>
                      handleMouseEnter("thanjavur", "தஞ்சாவூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Madurai.png"
                    alt=""
                    className="img-fluid Madurai_hub c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "madurai" : "மதுரை")
                          ? "block"
                          : "none",
                    }}
                    onClick={() => handleDistrict("madurai", "மதுரை")}
                    onMouseEnter={() => handleMouseEnter("madurai", "மதுரை")}
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/selected_map/Tirunelveli.png"
                    alt=""
                    className="img-fluid Tirunelveli_hub c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "tirunelveli" : "திருநெல்வேலி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("tirunelveli", "திருநெல்வேலி")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("tirunelveli", "திருநெல்வேலி")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
              </div>
            </Container>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Container>
              <div className="map-content">
                <h2 className="heading-line">
                  <span className="heading-line_span"></span>{" "}                
                    <span className="chennai-text">
                      {selectedHub && selectedHub.name}
                    </span>              
                </h2>               
                  <p className="map-detail-parag">
                    {selectedHub && selectedHub.brief}
                  </p>
                <div className="map-boxdetails mt-3">
                  <div className="map-det-flex">
                    <div className="map-boxdetails-div">
                      <h4>{selectedHub && selectedHub.startups}</h4>
                      <p>{t("Map.startups")}</p>
                    </div>
                    <div className="map-boxdetails-div">
                      <h4>{selectedHub && selectedHub.universities}</h4>
                      <p>{t("Map.educationalinstitutions")}</p>
                    </div>
                    <div className="map-boxdetails-div">
                      <h4>{selectedHub && selectedHub.incubationCenter}</h4>
                      <p>{t("Map.incubationcenters")}</p>
                    </div>

                    <div className="map-boxdetails-div">
                      <h4>{selectedHub && selectedHub.corporates}</h4>
                      <p>{t("Map.corporates")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default MapSection;
