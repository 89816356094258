import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Box, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
const UndermaintainNavbar = () => {
  const { navigator, removeItem, checkSessionTimout } = useContext(DataContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useDidMountEffect(() => {
    // checkSessionTimout()
    const handleScroll = () => {
      if (window.scrollY > 50) {  // Adjust the scroll position value as needed
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  let logo = localStorage.getItem('logo')
  let name = localStorage.getItem('name')
  let token = localStorage.getItem("jwttoken")
  const logoutBtn = () => {
    handleCloseUserMenu()
    localStorage.clear()
    sessionStorage.clear()
    navigator('/login')
  }
  const handleProfileType = () => {
    navigator('/profile')
    removeItem()
  }

  return (
    <>
      <div className={`${isScrolled ? 'aleart-msg set-bg' : 'aleart-msg1 set-bg'}`} >
        <span className="sys_view">
          <span className="colo-yellow">You are using a beta version of our website. We are still refining features. If you experience any problems or have suggestions, please contact us at tech@startuptn.in</span>
        </span>
        <span className="mob_view">
          <span className="colo-yellow">This is a beta version, if you experience any problems please contact us at tech@startuptn.in</span>
        </span>
      </div>
      <div className={`${isScrolled ? 'scrolled fixed-tops' : 'fixed-top'}`}>
        <div className={token !== null ? 'container' : 'container-fluid margin-css'}>
          <nav className="navbar navbar-expand-lg navbar-light bg-lights nav-pads">
            <Link className="navbar-brand nav-log-res1 mt-0" to="/" onClick={removeItem}>
              <img src="/images/logo/Group 1707479840.png" alt="" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">


              <Link to="/" onClick={removeItem}>
                <img
                  className="img-fluid nav-logo-profile"
                  src="/images/logo/Group 1707479840.png"
                  alt=""
                />
              </Link>

              <ul className="navbar-nav nav-items m-auto">
              <li className="nav-item mr-1">
                  <Link className="nav-link menues">About Us</Link>
                </li>
                <li className="nav-item mr-1">
                  <Link className="nav-link menues">Go-TN Entities</Link>
                </li>
                {/* Dropdown 1 */}
                <li className="nav-item dropdown mr-1">
                  <Link
                    className={`nav-link menues dropdown-toggle`}
                    role="button"
                    style={{ color: 'blue' }}
                  >
                    For Startups
                  </Link>
                </li>
                <li className="nav-item mr-1">
                  <Link className="nav-link menues" >Events & Media</Link>
                </li>                
              </ul>

              {/* More Navbar Links */}
              <ul className="navbar-nav">
                <div className="nav-align">
                  {/* <button className="search-buttons" ><img src="/images/search-icon.png" alt="" className="" /></button> */}
                  {
                    token === null ? (
                      <>
                        {/* <Link to={'/login'} className="nav-buttons" onClick={removeItem} style={{ color: '#fff' }}>Login</Link>
                        <button className="nav-button" onClick={handleProfileType}>Get Started</button> */}
                      </>
                    ) : (
                      <Box sx={{ flexGrow: 0 }}>
                        <Tooltip onClick={handleOpenUserMenu}>
                          <img
                            className="user-brandnewlogo"
                            src={(logo !== "undefined" && logo !== null) ? logo : "/images/user.png"}
                            alt={name}
                          />
                        </Tooltip>
                        <Menu
                          sx={{ mt: '45px' }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          <MenuItem onClick={logoutBtn} >
                            <Typography textAlign="center">Logout</Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    )
                  }
                </div>
              </ul>
            </div>
          </nav>
        </div>
      </div>

    </>
  )
}

export default UndermaintainNavbar

