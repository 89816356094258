import React, { useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DataContext from "../../../context/DataContext";
import { InputBase, styled, Button } from "@mui/material";
import CustomDateRangePicker from "../../common-pages/CustomDateRangePicker";
import { NavLink } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "24px",
  backgroundColor: "rgb(255 255 255)",
  border: "1px solid #00000017",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#d9d9d9",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "18ch",
      "&:focus": {
        width: "27ch",
      },
    },
  },
}));

const LatestNewsFilter = ({ setSearchInputValue, setPage }) => {
  const { navigator, setStartDate, setEndDate, hasPrivilege } =
    useContext(DataContext);
  const handleLatestnewsAdd = () => {
    navigator("/admin/latestnews/add");
  };

  const handleSearch = (val) => {
    debugger
    if (val.length > 2) {
      setSearchInputValue(val);
      setPage(1)
    } else {
      setSearchInputValue("");
    }
  };
  // const handleSearch = (e) => {
  //   const timerId = setTimeout(() => {
  //     setSearchInputValue(e.target.value);
  //     setPage(1)
  //   }, 1500); 

  //   return () => clearTimeout(timerId);
  // };

  return (
    <section>
      <div className="double-clr">
        {/* <div className="frt-gry">
          <div className="main-tab-adminpage">
            <NavLink className="startup-sup-btn1" to={"/admin/latestnews/list"}>
              Latest News & Updates
            </NavLink>
          </div>
        
        </div> */}
        <div className="sec-clrk">
          <div className="search-div">
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={"Search Latest News & Updates"}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => handleSearch(e.target.value)}
                type="search"
              />
            </Search>
          </div>
          <div className="sec-filtersk ml-auto">
            <div>
              {hasPrivilege("67") && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{
                    textTransform: "none",
                    background: "#0061D4",
                    borderRadius: "10px",
                  }}
                  onClick={handleLatestnewsAdd}
                >
                  Create Latest News & Updates
                </Button>
              )}
            </div>
            <div>
              <CustomDateRangePicker
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestNewsFilter;
