import {
  Dialog,
  DialogContent,
  DialogContentText,
  Pagination,
  Stack,
  Grid,
  Divider,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Eventregister from "./Eventregister";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DataContext from "../../../../context/DataContext";
import Mediafilter from "../Mediafilter";
import Norecordsfound from "../../../common-pages/Norecordsfound";
const Eventlist = () => {
  const { navigator, setPageLoading, fullScreen } = useContext(DataContext);
  const [opens, setOpens] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [depId, setDepId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [zoneName, setZoneName] = useState("");
  const [registerOpen, setRegisterOpen] = useState();
  const [distData, setDistData] = useState("");

  useDidMountEffect(() => {
    listApi();
  }, [page, searchInputValue, categoryId, startDate, endDate, depId, distData]);
  const [eventList, setEventList] = useState([]);
  let token = localStorage.getItem("jwttoken");
  let useId = localStorage.getItem("userId");
  const listApi = () => {
    setEventList([]);
    setPageLoading(true);
    var req = {
      categoryId: categoryId,
      listSize: 6,
      pageNumber: page,
      fromDate: startDate,
      toDate: endDate,
      departmentId: depId,
      district: distData,
    };
    apiService(`event/home/list`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setTotalPage(result.data.totalPages);
          if (result.data.event) {
            setEventList(result.data.event);
         
            setRegisterOpen(result.data.registrationEndDate);
          }
        }
      })
      .catch((err) => { });
  };
  const [regCateId, setRegCateId] = useState("");
  // const handleRegister = (val) => {
  //   var token = localStorage.getItem("jwttoken");
  //   if (token === "") {
  //     navigator("/login");
  //   } else if (token === null) {
  //     navigator("/login");
  //   } else {
  //     setRegCateId(val);
  //     setOpens(true);
  //   }
  // };

  const handleRegister = (val, url) => {
    if (token) {
      var req = {
        userId: Number(useId),
        eventId: val,
      };
      apiService(`event/count`, req, "post");

      if (url !== undefined) {
        const fullUrl = /^https?:\/\//.test(url) ? url : `https://${url}`;

        window.open(fullUrl, "_blank");
      }
    } else {
      navigator("/login");
    }
  };
  const handleMap = (url) => {
    if (url !== undefined) {
      window.open(url, "_blank");
    }
  };
  const handleReportClose = () => {
    setOpens(false);
    listApi();
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const isRegistrationClosed = (registrationEndDate) => {
    if (!registrationEndDate) return true;

    const currentDate = new Date();
    const registrationEnd = new Date(registrationEndDate);

    // Normalize both dates to midnight to ignore time comparison
    currentDate.setHours(0, 0, 0, 0);
    registrationEnd.setHours(0, 0, 0, 0);

    // Check if the current date is strictly greater than the registration end date
    return currentDate > registrationEnd;
  };
  const handleViewEventDetails = (val) => {
    var location = window.location.pathname
    localStorage.setItem("EventLocation", location)
    navigator(`/event/${btoa(val)}`)
  };
  return (
    <>
      <div className="event-title">
        <h2 className="event-titles">Events</h2>
        <div className="event-line">
          <img src="/images/event-line.png" alt="" />
        </div>
      </div>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Grid item sm={12} md={3} lg={3}>
          <Mediafilter
            endDate={endDate}
            setEndDate={setEndDate}
            startDate={startDate}
            setStartDate={setStartDate}
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            setSearchInputValue={setSearchInputValue}
            setCategoryId={setCategoryId}
            type={"event"}
            seachLableName={"Enter Event Name"}
            setDepId={setDepId}
            setZoneId={setZoneId}
            setZoneName={setZoneName}
            setDistData={setDistData}
            distData={distData}
            page={page}setPage={setPage}
          />
        </Grid>
        <Grid item sm={12} md={9} lg={9}>
          <div className="container-fluid">
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={3}
            >
              {eventList.length > 0 ? (
                eventList.map((item, i) => (
                  <Grid item xs={12} sm={12} md={12} lg={6} key={i}>
                    <div className="event-list" onClick={() => handleViewEventDetails(item.id)} style={{ cursor: 'pointer' }}>
                      <div
                        className="card event-crd"

                      >
                        <div className="event-img">
                          <img
                            src={
                              item.imageName
                                ? item.imageName
                                : "/images/oneTn/white-image.png"
                            }
                            className="card-img-top"
                            alt=""
                          />
                          {item.category && (
                            <div className="img-top">
                              <span>{item.category ? item.category : ""}</span>
                            </div>
                          )}

                          {/* <div className="event-time-top">
                            <div className="home-event-days">
                              {item.startDay && (
                                <>
                                  <div className="time-bg">
                                    <h6 className="time-color">
                                      {item.startDay}
                                    </h6>
                                    <span>{item.startMonth}</span>
                                  </div>
                                </>
                              )}
                              {item.endDay && (
                                <>
                                  <div className="time-bg ml-2">
                                    <h6 className="time-color">
                                      {item.endDay}
                                    </h6>
                                    <span>{item.endMonth}</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div> */}
                        </div>
                        <div
                          className="card-body"
                          style={{ padding: "10px", position: "relative" }}
                        >
                          <div className="event-details">
                            <h5>{item.title}</h5>
                            <div className="event-clockss">
                              <div>
                                <h6 className="event-time mt-2">
                                  <img
                                    src="/images/calendarimg.png"
                                    alt=""
                                    className="mr-2"
                                  />
                                  {item.startMonth ? item.startMonth : ""} {item.startDay ? item.startDay : ""},{" "}{item.fromyear ? item.fromyear : ""}  {item.endMonth ? `- ${item.endMonth}` : ""} {item.endDay ? item.endDay : ""}{item.endDay ? (`,${" "}${item.toyear ? item.toyear : ""}`): ("")}
                                </h6>
                              </div>
                              <Divider orientation="vertical" variant="middle" flexItem sx={{
                                borderWidth: 1, 
                                fontWeight: 'bold',  
                              }} />
                              <div >
                                <h6 className="event-time mt-2">
                                  <img
                                    src="/images/clock-icon.png"
                                    alt=""
                                    className="mr-2"
                                  />
                                  {item.time ? item.time : "-"}
                                </h6>
                              </div>
                            </div>
                            <div className="item-location">
                              {item.district ? (
                                <h6 className="event-location">
                                  <img
                                    src="/images/location-icon.png"
                                    alt=""
                                    className="mr-2"
                                    onClick={() => handleMap(item.mapLink)}
                                  />
                                  {item.venue} ,&nbsp;{item.district}&nbsp;
                                  {item.pinCode}
                                </h6>
                              ) : (
                                <h6 className="event-location">
                                  <img
                                    src="/images/online-symbal.png"
                                    alt=""
                                    className="mr-2"
                                  />
                                  {item.mode}
                                </h6>
                              )}
                            </div>
                            <div className="reg-fees2 my-2">
                              <div>
                                {item.paid === false ? (
                                  <span className="reg-titles">Free</span>
                                ) : (
                                  <span className="reg-titles">
                                    ₹&nbsp;{item.amount}
                                  </span>
                                )}
                              </div>
                              <div>
                                {!isRegistrationClosed(
                                  item.registrationEndDate
                                ) ? (
                                  <button
                                    className="reg-btn"
                                    onClick={() =>
                                      handleRegister(item.id, item.redirectUrl)
                                    }
                                  >
                                    Register
                                  </button>
                                ) : (
                                  ""

                                  // <span className="registration-closed">Registration Closed</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))
              ) : (
                <>
                  <Norecordsfound name="Events Not Found" />
                </>
              )}
            </Grid>
            {totalPage > 1 ? (
              <div className="event-pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Grid>
      </Grid>
      <Dialog fullScreen={fullScreen} maxWidth={"lg"} open={opens}>
        <DialogContent sx={{ minWidth: "800px" }}>
          <div>
            <span
              style={{ color: "#2260FF", cursor: "pointer" }}
              onClick={handleReportClose}
            >
              <ArrowBackIcon /> Back
            </span>
          </div>
          <Eventregister
            categoryId={regCateId}
            setOpens={setOpens}
            via={"home"}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Eventlist;
