import React, { useContext, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Button, Container, Grid } from "@mui/material";
import DataContext from "../../../context/DataContext";
import Footer from "../footer-page/Footer";
import HomeEventSection from "./HomeEventSection";
import HomeServicesSection from "./HomeServicesSection";
import BannerFilter from "./BannerFilter";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import MapSection from "./MapSection";
import Navbar from "../navbar-page/Navbar";
import Aos from "aos";
import { Link } from "react-router-dom";
import cryptoJS from "../../../hooks/cryptoJS";

const HomePage = () => {
  const {
    navigator,
    setPageLoading,
    validateEmail,
    isParsonaVisible,
    setIsParsonaVisible,
    isProfileTypeVisible,
    setIsProfileTypeVisible,
    t,
  } = useContext(DataContext);

  const [isTamil, setIsTamil] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [awardData, setAwardData] = useState([])
  const [displayLogo, setDisplayLogo] = useState([])

  const lang = localStorage.getItem("lang");

  useDidMountEffect(() => {
    awardgetApi()
    logodisplayapi()
  }, [lang])
  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  useEffect(() => {
    // Calculate the total time for one cycle of the Typewriter effect
    const typeSpeed = 200;
    const deleteSpeed = 50;
    const delaySpeed = 2000;

    // Calculate the duration of one complete typewriter cycle (typing + pause + deleting + pause)
    const totalCycleTime =
      (isTamil ? "தமிழ்நாடு" : "Tamil Nadu").length * typeSpeed +
      delaySpeed +
      (isTamil ? "தமிழ்நாடு" : "Tamil Nadu").length * deleteSpeed +
      delaySpeed;

    const changelang = setInterval(() => {
      setIsTamil((prevIsTamil) => !prevIsTamil);
    }, totalCycleTime);

    // Clear interval on component unmount
    return () => clearInterval(changelang);
  }, [isTamil]);

  const [emailId, setEmailId] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const handleEmailId = (e) => {
    setEmailId(e.target.value.toLowerCase());
    setEmailErrorMsg(validateEmail(e.target.value));
    setCheckEmail(false);
  };
  const handleNewsLetter = () => {
    console.log(emailErrorMsg);
    if (emailId !== "") {
      if (emailErrorMsg === "Invalid email address") {
        return;
      }
      var email = emailId;
      setCheckEmail(false);
      var req = {
        email: cryptoJS(emailId.toLowerCase()),
      };
      console.log(email, "email");
      apiService(`home/newsletter/subscribe?email=${email}`, req, "post")
        .then((result) => {
          if (result && result.data && result.data.responseMessage) {
            notifyService("success", "Success", result.data.responseMessage);
            setEmailId("");
          }
        })
        .catch((err) => { });
    } else {
      setCheckEmail(true);
      setEmailErrorMsg(false);
    }
  };
  const awardgetApi = () => {
    // setPageLoading(true)
    var req = {
      lang: lang === "en" ? "ENG" : "TAM",
    }
    apiService('award/home/list', req, 'unauthpost')
      .then((res) => {
        setPageLoading(false)
        setAwardData(res?.data?.awards)
      }).catch((err) => {
        console.log(err);
      });
  }
  const logodisplayapi = () => {
    setPageLoading(true)
    apiService('department/home/list', {}, 'unauthpost')
      .then((res) => {
        setPageLoading(false)
        if (res && res.data && res.data.departments) {
          setDisplayLogo(res.data.departments)
        }

      }).catch((err) => { console.log(err); });
  }


  // const logosToShow = displayLogo && displayLogo.slice(0, 10);
  const redirectgotn = () => {
    navigator('/department')
  }
  // BannerFilter Page

  const [isSearchVisible, setSearchVisible] = useState(false);
  const searchRef = useRef(null);
  useEffect(() => {
    Aos.init();
  }, []);
  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchVisible(false);
    }
  };
  const handleDownBtn = () => {
    const refdp = document.getElementById("about");
    refdp.scrollIntoView();
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [screenSize, setScreenSize] = useState(5);
  useEffect(() => {
    size();
  }, []);
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(3);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(3);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(3);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(3);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(3);
    } else {
      setScreenSize(1);
    }
  };
  const handleRedirect = (name) => {
    if (name !== undefined) {
      const fullUrl = name.startsWith('http://') || name.startsWith('https://') ? name : 'https://' + name;

      window.open(fullUrl, '_blank');
    }
  }
  return (
    <div
      className="main_home_bg"
      onClick={(e) => {
        isParsonaVisible && setIsParsonaVisible(false);
        isProfileTypeVisible && setIsProfileTypeVisible(false);
      }}
    >
      <Navbar />
      <div className="sample">
        <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          onSlideChange={handleSlideChange}
          autoplay={{ delay: 1000 }}
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <div className="banner-slide1">
              <div className="video-background">
                <img
                  src="/images/icon/oneTn_video.gif"
                  alt="Description of GIF"
                  className="video_css banner-image"
                />
              </div>

              <div className="container banner-content1 text-center">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* <h1 className="make_h1">  Making <span className={` ${isTamil ? 'tamiltext' : 'englishtext'}`}>{isTamil ? "தமிழ்நாடு" : "Tamil Nadu"}</span></h1> */}
                    <img
                      className="img-fluid banner-image-main"
                      src="/images/oneTn/new_logo.png"
                      alt=""
                    />
                    {lang === "en" ? (
                      <p className="main-head-banner">
                        {" "}
                        <span className="bold-span">O</span>ne platform to{" "}
                        <span className="bold-span">N</span>urture next-gen{" "}
                        <span className="bold-span">E</span>conomy

                      </p>
                    ) : (
                      <h1
                        className=""
                        style={{ color: "white" }}
                      >
                        {" "}
                        அடுத்த தலைமுறை பொருளாதாரத்தை வளர்ப்பதற்கான ஒரே தளம்

                      </h1>
                    )}
                  </Grid>
                </Grid>

                {/* <BannerFilter /> */}
                <div className="text-center mouse-icon-1">
                  <img
                    src="./images/mouse-icon.png"
                    alt=""
                    className="c-pointer"
                    onClick={handleDownBtn}
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="center-postion">
          <div className="container">
            {/* {activeIndex === 1 && ( */}
            <BannerFilter />
            {/* )} */}
          </div>
        </div>
      </div>
      <div className="Cm-photo-div" id="about">
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="cm-image">
                <img
                  className="cmimag img-fluid"
                  src={"/images/oneTn/Group 1707479871.png"}
                  alt="cmimage"
                  style={{ width: "70%" }}
                />
              </div>
              <div>
                {lang === "en" ? (
                  <p className="left-para-cm mb-4">
                    “We have set ourselves an ambitious target of becoming a{" "}
                    <span style={{ fontWeight: 600 }}>
                      {" "}
                      $1 Trillion economy by 2030
                    </span>
                    ”
                  </p>
                ) : (
                  <p className="left-para-cm mb-4">
                    <span style={{ fontWeight: 600 }}>
                      {" "}
                      "2030-ம் ஆண்டிற்குள் 1 டிரில்லியன் டாலர்{" "}
                    </span>
                    பொருளாதார மாநிலமாக உயர்த்த வேண்டும் என்ற லட்சிய இலக்கை நாமே
                    நிர்ணயித்துள்ளோம்"
                  </p>
                )}
                <div className="text-center">
                  <h6 style={{ color: "#0B40AA", fontWeight: 600 }}>
                    - {t("Homepage.Banner.cmpara2")}
                  </h6>
                  <p className="sml-p">{t("Homepage.Banner.cmpara3")}</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div className="left-content-homepage">
                <div className="anim-center-cont">
                  {lang === "en" ? (
                    <p className="paras-super">
                      {" "}
                      Empowering Startup Journey <br /> Through
                      <span style={{ fontWeight: "600" }}>
                        {" "}
                        Unified Go TN Support
                      </span>
                    </p>
                  ) : (
                    <p className="paras-super">
                      <span style={{ fontWeight: "600" }}>
                        {" "}
                        ஒருங்கிணைந்த தமிழ்நாடு
                      </span>{" "}
                      அரசு ஆதரவு மூலம் ஸ்டார்ட் அப் பயணத்தை மேம்படுத்துதல்
                    </p>
                  )}
                </div>
                <section className="department-section2">
                  <Container>
                    <div>
                      <Grid container spacing={3} justifyContent={"center"}>
                        {displayLogo && displayLogo.map((logo, i) => (
                          <Grid item xs={12} sm={4} md={3} lg={2} xl={2} key={i}>
                            <div className="departments-img c-pointer">
                              <div onClick={() => handleRedirect(logo.redirect)}>
                                {logo.logoName ? (<img
                                  src={logo.logoName}
                                  alt="Department_ Logo"
                                  className="dep-img1"
                                  style={{ mixBlendMode: "darken" }}
                                />) : (<img
                                  src={'/images/racket-img.png'}
                                  alt="Department_ Logo"
                                  className="dep-img1-no-img"
                                  style={{ mixBlendMode: "darken" }}
                                />)}
                              </div>
                            </div>
                          </Grid>
                        ))}

                      </Grid>
                    </div>
                    {/* <div className="text-center mt-2">
                      {displayLogo.length > 30 && (
                        <Button variant="outlined" onClick={() => redirectgotn()} sx={{
                          background: 'linear-gradient(90deg, rgba(218, 69, 137, 1) 11%, rgba(224, 87, 128, 1) 24%, rgba(248, 161, 91, 1) 98%)',
                          color: "#fff",
                          fontSize: '12px',
                          padding: "8px 20px",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          textTransform: 'none'
                        }}>
                          View More
                        </Button>
                      )}
                    </div> */}
                  </Container>
                </section>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <section className="awards-recognitions-section">
        <Container>
          <h4>{t("Homepage.Banner.awardsandrecognitions")}</h4>
          {awardData && awardData.length > 0 ? (
            <div className="mt-3">
              <Swiper
                spaceBetween={30}
                slidesPerView={screenSize}
                loop
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                modules={[Autoplay]}
              >
                {awardData && awardData.map((item, i) => (
                  <SwiperSlide key={i}>
                    <div className="awards-recognitions-div">
                      <div className="awards-images">
                        <img src={item.logoName} alt="Award Logo" style={{
                          height: '55px'
                        }} />
                      </div>
                      <h3>{item.tamilTitle}</h3>
                      <p>{item.description}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : (<>
            <h3
              style={{
                color: "#da4589",
                textAlign: "center",
                padding: "70px 0px",
              }}
            >
              {t("No Data Found")}
            </h3>
          </>)}
        </Container>
      </section>
      <MapSection />
      <HomeServicesSection />
      <HomeEventSection />
      {/* <section className="news-sms-section">
        <Container>
          <div className="news-sms-details">
            <img src="/images/oneTn/onetn_fav.png" alt="" className="mb-3" />
            <h4 className="font-color-blue mb-3">
              {lang === "en" ? (
                <b>
                  The All-in-One Platform for{" "}
                  <span className="gradient-text">
                    TamilNadu Startups Journey
                  </span>
                </b>
              ) : (<b>
                <span className="gradient-text">
                  ஸ்டார்ட்அப் பயணத்திற்கான{" "}
                </span>
                அனைத்து தகவல்களையும் உள்ளடக்கிய ஒரே தளம்
              </b>)}
            </h4>
            <p className="mb-3 font-color-gray">
              {t("contact.p")}
            </p>
            <div>
              <div className="home-join">
                <div className="p-relative">
                  <img
                    src="./images/input-email.png"
                    alt=""
                    className="input-email-img"
                  />
                  <input
                    className="input-home"
                    type="email"
                    placeholder={t("contact.placeholder")}
                    value={emailId}
                    onInput={(e) => handleEmailId(e)}
                  />
                </div>
                <button
                  className="join-button"
                  type="submit"
                  onClick={handleNewsLetter}
                >
                  {t("contact.button")}
                </button>
              </div>
              {checkEmail === true && (
                <p className="text-danger email-require">Email is required</p>
              )}
              {emailErrorMsg && (
                <p className="text-danger email-errors">{emailErrorMsg}</p>
              )}
            </div>
           
          </div>
        </Container>
      </section> */}
      <Footer />
    </div>
  );
};

export default HomePage;
