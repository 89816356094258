import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Adminlayout from "./Adminlayout";
import AdminEventLayout from "./event-details/AdminEventLayout";
import AdminEventList from "./event-details/AdminEventList";
import AdminEventAdd from "./event-details/AdminEventAdd";
import StartupSupportLayout from "./support-details/startup-support-page/StartupSupportLayout";
import StartupSupportList from "./support-details/startup-support-page/StartupSupportList";
import StartupSupportDetails from "./support-details/startup-support-page/StartupSupportDetails";
import TeamSupportLayout from "./support-details/team-support-page/TeamSupportLayout";
import TeamSupportList from "./support-details/team-support-page/TeamSupportList";
// import TeamSupportDetails from './support-details/team-support-page/TeamSupportDetails'
import AdminSupportRaiseTicket from "./support-details/AdminSupportRaiseTicket";
import AdminUserLayout from "./user-details/AdminUserLayout";
import AdminUserList from "./user-details/AdminUserList";
import AdminSupportLayout from "./support-details/AdminSupportLayout";
import DepartmentLayout from "./department-details/DepartmentLayout";
import DepartmentList from "./department-details/DepartmentList";
import DepartmentAdd from "./department-details/DepartmentAdd";
import DepartmentDetails from "./department-details/info-page/DepartmentDetails";
import AdminEventHistory from "./event-details/AdminEventHistory";
import MeetingLayout from "./meeting-details/MeetingLayout";
import MeetingList from "./meeting-details/MeetingList";
import MeetingReport from "./meeting-details/MeetingReport";
import MeetingAdd from "./meeting-details/MeetingAdd";
import AdminSupportDashboard from "./support-details/AdminSupportDashboard";
import BudgetLayout from "./budget-details/BudgetLayout";
import BudgetAllocation from "./budget-details/BudgetAllocation";
import BudgetReport from "./budget-details/BudgetReport";
import BudgetAdd from "./budget-details/BudgetAdd";
import ReportLayout from "./report-details/ReportLayout";
import ReportDocuments from "./report-details/ReportDocuments";
import AdminDashboard from "./dashboard-details/AdminDashboard";
import DepartmentAddCS from "./department-details/DepartmentAddCS";
import DataContext from "../../context/DataContext";
import AdminEventVenue from "./event-details/venue-details/AdminEventVenue";
import SmartcardLayout from "./smartcard-details/SmartcardLayout";
import Smartcardlist from "./smartcard-details/Smartcardlist";
import ServicePartnersLayout from "./servicepartners-details/ServicePartnersLayout";
import Servicepartnerslist from "./servicepartners-details/Servicepartnerslist";
import ProjectList from "./Project-details/ProjectList";
import ProjectLayout from "./Project-details/ProjectLayout";
import ProjectAdd from "./Project-details/ProjectAdd";
import { AdminBlogLayout, AdminBlogList, AdminBlogAdd } from "./blog-details";
import {
  NewslettersAdd,
  NewslettersLayout,
  NewslettersList,
} from "./newsletters-details";
import {
  LatestNewsAdd,
  LatestNewsLayout,
  LatestNewsList,
} from "./latest-news-details";
import AdminEventViewdetails from "./event-details/AdminEventViewdetails";
import HomePageLayout from "./Homepage-tab/HomePageLayout";
// import AdminBlogList from "./blog-details/AdminBlogList";
// import AdminBlogLayout from "./blog-details/AdminBlogLayout";
// import AdminBlogAdd from "./blog-details/AdminBlogAdd";

const Adminroutes = () => {
  const { hasPrivilege } = useContext(DataContext);
  const role = localStorage.getItem("role");
  return (
    <main>
      <Routes>
        <Route path="/admin" element={<Adminlayout />}>
          {/* <Route path="/admin" element={<Navigate replace to={hasPrivilege("1") ? '/admin/dashboard' : '/admin'} />} /> */}
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/event" element={<AdminEventLayout />}>
            <Route path="/admin/event" element={<Navigate replace to="/admin/event/history" />}/>
            <Route path="/admin/event/list" element={<AdminEventList />} />
            <Route path="/admin/event/add" element={<AdminEventAdd />} />
            <Route path="/admin/event/venue" element={<AdminEventVenue />} />
            <Route path="/admin/event/:id" element={<AdminEventAdd />} />
            <Route path="/admin/event/history" element={<AdminEventHistory />}/>
          </Route>
         
          <Route path="/admin/blog" element={<AdminBlogLayout />}>
            <Route
              path="/admin/blog"
              element={<Navigate replace to="/admin/blog/list" />}
            />
            <Route path="/admin/blog/list" element={<AdminBlogList />} />
            <Route path="/admin/blog/add" element={<AdminBlogAdd />} />
            <Route path="/admin/blog/:id" element={<AdminBlogAdd />} />
          </Route>
          <Route path="/admin/newsletter" element={<NewslettersLayout />}>
            <Route
              path="/admin/newsletter"
              element={<Navigate replace to="/admin/newsletter/list" />}
            />
            <Route
              path="/admin/newsletter/list"
              element={<NewslettersList />}
            />
            <Route path="/admin/newsletter/add" element={<NewslettersAdd />} />
            <Route path="/admin/newsletter/:id" element={<NewslettersAdd />} />
          </Route>
          <Route path="/admin/latestnews" element={<LatestNewsLayout />}>
            <Route
              path="/admin/latestnews"
              element={<Navigate replace to="/admin/latestnews/list" />}
            />
            <Route path="/admin/latestnews/list" element={<LatestNewsList />} />
            <Route path="/admin/latestnews/add" element={<LatestNewsAdd />} />
            <Route path="/admin/latestnews/:id" element={<LatestNewsAdd />} />
          </Route>
          <Route path="/admin/support" element={<AdminSupportLayout />}>
            <Route
              path="/admin/support"
              element={
                <Navigate
                  replace
                  to={
                    hasPrivilege("42")
                      ? "/admin/support/dashboard"
                      : "/admin/support/startup"
                  }
                />
              }
            />
            <Route
              path="/admin/support/startup"
              element={<StartupSupportLayout />}
            >
              <Route
                path="/admin/support/startup"
                element={<Navigate replace to="/admin/support/startup/list" />}
              />
              <Route
                path="/admin/support/startup/list"
                element={<StartupSupportList />}
              />
              <Route
                path="/admin/support/startup/:id"
                element={<StartupSupportDetails name={"startup"} />}
              />
              <Route
                path="/admin/support/startup/raise"
                element={<AdminSupportRaiseTicket name={"startup"} />}
              />
            </Route>
            <Route path="/admin/support/team" element={<TeamSupportLayout />}>
              <Route
                path="/admin/support/team"
                element={<Navigate replace to="/admin/support/team/list" />}
              />
              <Route
                path="/admin/support/team/list"
                element={<TeamSupportList />}
              />
              <Route
                path="/admin/support/team/:id"
                element={<StartupSupportDetails name={"team"} />}
              />
              <Route
                path="/admin/support/team/raise"
                element={<AdminSupportRaiseTicket name={"team"} />}
              />
            </Route>
            <Route
              path="/admin/support/dashboard"
              element={<AdminSupportDashboard name={"dashboard"} />}
            />
          </Route>
          <Route path="/admin/user" element={<AdminUserLayout />}>
            <Route
              path="/admin/user"
              element={<Navigate replace to="/admin/user/list" />}
            />
            <Route path="/admin/user/list" element={<AdminUserList />} />
          </Route>
          <Route path="/admin/department" element={<DepartmentLayout />}>
            <Route
              path="/admin/department"
              element={<Navigate replace to="/admin/department/list" />}
            />
            <Route path="/admin/department/list" element={<DepartmentList />} />
            <Route
              path="/admin/department/add"
              element={
                role === "ADMINISTRATOR" ? (
                  <DepartmentAddCS />
                ) : (
                  <DepartmentAdd />
                )
              }
            />
            <Route
              path="/admin/department/update/:id"
              element={<DepartmentAdd />}
            />
            <Route
              path="/admin/department/:id"
              element={<DepartmentDetails />}
            />
          </Route>
          <Route path="/admin/homepage" element={< HomePageLayout/>}/>
          <Route path="/admin/project" element={<ProjectLayout />}>
            <Route
              path="/admin/project"
              element={<Navigate replace to="/admin/project/list" />}
            />
            <Route path="/admin/project/list" element={<ProjectList />} />
            <Route path="/admin/project/add" element={<ProjectAdd />} />
            <Route path="/admin/project/add/:id" element={<ProjectAdd />} />
          </Route>
          <Route path="/admin/meeting" element={<MeetingLayout />}>
            <Route
              path="/admin/meeting"
              element={<Navigate replace to="/admin/meeting/list" />}
            />
            <Route path="/admin/meeting/list" element={<MeetingList />} />
            <Route path="/admin/meeting/report" element={<MeetingReport />} />
            <Route path="/admin/meeting/add" element={<MeetingAdd />} />
            <Route path="/admin/meeting/update/:id" element={<MeetingAdd />} />
          </Route>
          <Route path="/admin/budget" element={<BudgetLayout />}>
            <Route
              path="/admin/budget"
              element={<Navigate replace to="/admin/budget/allocation" />}
            />
            <Route
              path="/admin/budget/allocation"
              element={<BudgetAllocation />}
            />
            <Route path="/admin/budget/report" element={<BudgetReport />} />
            <Route path="/admin/budget/add" element={<BudgetAdd />} />
            <Route path="/admin/budget/update/:id" element={<BudgetAdd />} />
          </Route>
          <Route path="/admin/report" element={<ReportLayout />}>
            <Route
              path="/admin/report"
              element={<Navigate replace to="/admin/report/documents" />}
            />
            <Route
              path="/admin/report/documents"
              element={<ReportDocuments />}
            />
          </Route>
          <Route path="/admin/smartcard" element={<SmartcardLayout />}>
            <Route
              path="/admin/smartcard"
              element={
                <Navigate
                  replace
                  to={hasPrivilege("51") ? "/admin/smartcard/list" : "/admin"}
                />
              }
            />
            <Route path="/admin/smartcard/list" element={<Smartcardlist />} />
          </Route>
          <Route
            path="/admin/servicepartners"
            element={<ServicePartnersLayout />}
          >
            <Route
              path="/admin/servicepartners"
              element={
                <Navigate
                  replace
                  to={
                    hasPrivilege("51")
                      ? "/admin/servicepartners/list"
                      : "/admin"
                  }
                />
              }
            />
            <Route
              path="/admin/servicepartners/list"
              element={<Servicepartnerslist />}
            />
          </Route>
        </Route>
        <Route path="event/:id"  element={<AdminEventViewdetails />}></Route>
      </Routes>
    </main>
  );
};

export default Adminroutes;
