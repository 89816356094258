import React, { useContext, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase, styled, Button, Grid, TableContainer, Paper, Table, TableBody, TableRow, TableCell, TablePagination, TableHead, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import DataContext from '../../../../context/DataContext';
import Awardsandrecognitionsadd from './Awardsandrecognitionsadd';
import { MdEdit } from "react-icons/md";
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import { FaEye } from "react-icons/fa";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "24px",
  backgroundColor: "rgb(255 255 255)",
  border: "1px solid #00000017",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#d9d9d9",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "19ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Awardsandrecognitionslist = () => {
  const { setPageLoading, hasPrivilege } = useContext(DataContext);
  const [page, setPage] = useState(0);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [awardlistId1, setAwardlistId1] = useState(0)
  const [awardlistId2, setAwardlistId2] = useState(0)
  const [listData, setListData] = useState([])
  const [actions, setActions] = useState("")

  useDidMountEffect(() => {
    listApi();
  }, [searchInputValue, page,rowsPerPage]);

  const listApi = () => {
    setPageLoading(true);
    setListData([]);
    var data = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      searchString: searchInputValue,
    };
    apiService("award/admin/list", data, "post")
      .then((res) => {
        setPageLoading(false);
        if (res && res.data) {
          setCount(res.data.count);
          if (res.data.awards) {
            setListData(res.data.awards);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching Blogs:", err);
      });
  };

  const handleSearch = (e) => {
      setSearchInputValue(e.target.value);
      setPage(0)
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOpenModal = (val, id, id2) => {
    setOpenModal(true);
    setActions(val)
    if (val === 'update') {
      setAwardlistId1(id)
      setAwardlistId2(id2)
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <div className="admin-event-page">

      <div className="sec-clrk">
        <div className="sec-filtersk">
          <div className="search-div">
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={"Search by Title"}
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearch}
                type="search"
              />
            </Search>
          </div>
        </div>
        <div className='d-flex align-items-center'>
        {hasPrivilege("73") && (<div>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              size="small"
              sx={{
                textTransform: "none",
                borderRadius: "10px",
                background: '#083fb2'
              }}
              onClick={() => handleOpenModal('new')}
            >
              ADD
            </Button>
          </div>)}
         <div >
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ float: "right", margin: "0px" }}
            sx={{
              margin: 0,
              "& .MuiTablePagination-selectLabel": {
                marginBottom: 0,
              },
              "& .MuiTablePagination-displayedRows": {
                marginBottom: 0,
                "& p": {
                  marginBottom: "0 !important",
                },
              },
              "& .MuiTablePagination-root": {
                paddingBottom: 0,
              },
            }}
          />
          </div>
          
        </div>

      </div>
      <div className="event-datas-list py-2">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={4}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="table_main_div">
              {listData.length > 0 ? (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        borderCollapse: "separate",
                        borderSpacing: "0 10px",
                      }}
                    >
                      <TableHead>
                        <TableRow className="titles1">
                          <TableCell>Title</TableCell>
                          <TableCell sx={{ width: '50%' }}>Description</TableCell>
                          <TableCell align="center">Logo</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listData &&
                          listData.map((list, i) => (
                            <>
                              <TableRow
                                key={i}
                                sx={{
                                  position: "relative",
                                  "&:after": {
                                    content: '""',
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    borderRadius: "20px",
                                    pointerEvents: "none",
                                    marginBottom: "-1px",
                                  },
                                  "& > *": {
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    "&:last-of-type": {
                                      borderTopRightRadius: "20px",
                                      borderBottomRightRadius: "20px",
                                    },
                                  },
                                  marginBottom: "10px",
                                }}
                                className="Sup-list"
                              >
                                <TableCell className="list-1">
                                  <span >{list.title}</span>
                                </TableCell>
                                <TableCell>
                                  <span>{list.description}</span>
                                </TableCell>
                                <TableCell align="center">
                                  <img src={list.logoName} alt="award Logo" className="logo-award" />
                                </TableCell>
                                <TableCell align="center">
                                  <MdEdit onClick={() => handleOpenModal('update', list.id1, list.id2)} style={{ fontSize: '20px' }} />
                                </TableCell>
                              </TableRow>

                            </>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      count={count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      style={{ float: "right", margin: "0px" }}
                      sx={{
                        margin: 0,
                        "& .MuiTablePagination-selectLabel": {
                          marginBottom: 0,
                        },
                        "& .MuiTablePagination-displayedRows": {
                          marginBottom: 0,
                          "& p": {
                            marginBottom: "0 !important",
                          },
                        },
                        "& .MuiTablePagination-root": {
                          paddingBottom: 0,
                        },
                      }}
                    />
                  </TableContainer>
                </>
              ) : (
                <>
                  <div className="text-center nodatacss_admin">
                    <h5 className="mb-4">No Data Found</h5>
                    <div>
                      <img
                        src="/images/blue horizontal.png"
                        alt="blue horizontal.png"
                        className="logo_dash img-fluid"
                      />
                    </div>
                    <div className="mt-5">
                      <h3 className="title_p opa">OneTN</h3>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={openModal}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" sx={{ padding: '5px 40px', marginTop: '25px' }}>
          {actions === 'update' ? "Update" : "Add"} Awards and recognise
        </DialogTitle>
        <DialogContent sx={{ padding: '0px 40px' }}>
          <Awardsandrecognitionsadd listApi={listApi} handleCloseModal={handleCloseModal} actions={actions} awardlistId1={awardlistId1} awardlistId2={awardlistId2} />
        </DialogContent>
      </Dialog>
    </div>

  )
}

export default Awardsandrecognitionslist

